<template>
    <data-grid
        :data-source="entities.data"
        :columns="columns"
        :loading="loadingSearch"
    >
        <template #outer-header>
            <div
                class="flex w-full items-start justify-between gap-2 border-b px-6 py-4"
            >
                <p class="font-medium leading-9 text-gray-900">
                    {{ $t('settingsUsers.list.accountingFirms.title') }}
                </p>
                <base-tippy
                    v-if="
                        entities.total > 0 && $acl.can('update_user_settings')
                    "
                    placement="top"
                    :content="
                        $t('settingsUsers.list.accountingFirms.inviteTooltip')
                    "
                >
                    <base-button
                        variant="default"
                        @click="
                            $router.push({
                                name: 'settings.users.invite',
                            })
                        "
                        disabled
                    >
                        {{ $t('settingsUsers.list.accountingFirms.inviteBtn') }}
                    </base-button>
                </base-tippy>
                <base-button
                    v-if="!entities.total && $acl.can('update_user_settings')"
                    variant="default"
                    @click="
                        $router.push({
                            name: 'settings.users.invite',
                        })
                    "
                >
                    {{ $t('settingsUsers.list.accountingFirms.inviteBtn') }}
                </base-button>
            </div>
        </template>

        <template #column-name="{ item }">
            <users-avatar-group :user="item.accountant" />
        </template>
        <template #column-status>
            <base-badge variant="success" :label="$t('general.active')" />
        </template>
        <template #column-dateAdded="{ item }">
            {{ $filters.dateShortFormat(item.created_at) }}
        </template>
    </data-grid>
</template>

<script setup>
const { t } = useI18n()

const { entities, useApiSearch } = useApiFactory('users/accountants')
const { loading: loadingSearch } = useApiSearch()

const columns = [
    {
        property: 'name',
        label: t('settingsUsers.list.name'),
    },
    {
        property: 'status',
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
        label: t('settingsUsers.list.status'),
    },
    {
        property: 'dateAdded',
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
        label: t('settingsUsers.list.dateAdded'),
    },
]
</script>
