<template>
    <base-modal
        @modal-close="closeModal"
        :show="show"
        size="lg"
        has-separator
        :show-header="false"
    >
        <base-alert
            v-if="defaultCurrency"
            variant="warning"
            class="mb-2 mt-1"
            :description="
                $t('settingsOpeningBalances.warningModal.description', {
                    amount: $filters.currency(
                        Math.abs(difference),
                        defaultCurrency.iso
                    ),
                })
            "
        >
        </base-alert>

        <template #modal-footer>
            <div class="flex justify-end gap-4">
                <base-button variant="default" outline @click="closeModal">
                    {{ $t('settingsOpeningBalances.warningModal.goBackBtn') }}
                </base-button>

                <base-button @click="$emit('modal-confirm')">
                    {{ $t('settingsOpeningBalances.warningModal.continueBtn') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    difference: {
        type: Number,
        default: 0,
    },
})
const emit = defineEmits(['modal-close', 'modal-confirm'])
const { defaultCurrency } = useCurrencies()

const closeModal = () => {
    emit('modal-close')
}
</script>
