<template>
    <base-section :title="$t('settingsCompany.tax.title')">
        <template #description>
            <span class="text-sm font-normal text-gray-500">
                {{ $t('settingsCompany.tax.description') }}
            </span>
        </template>
        <template #right>
            <div class="grid grid-cols-2 gap-x-3 gap-y-4">
                <form-text-input
                    :label="$t('settingsCompany.tax.companyRegistrationLabel')"
                    :error-message="errorCompanyRegistration"
                    v-model="companyRegistration"
                />
                <form-text-input
                    :label="$t('settingsCompany.tax.vatIdLabel')"
                    :error-message="errorVatId"
                    v-model="vatId"
                />
                <form-text-input
                    :label="$t('settingsCompany.tax.taxReferenceLabel')"
                    :error-message="errorTaxReference"
                    v-model="taxReference"
                />
            </div>
        </template>
    </base-section>
</template>

<script setup>
import { useField } from 'vee-validate'

const { value: companyRegistration, errorMessage: errorCompanyRegistration } =
    useField('registration_number')
const { value: vatId, errorMessage: errorVatId } = useField('vat_id')
const { value: taxReference, errorMessage: errorTaxReference } =
    useField('tax_reference')
</script>
