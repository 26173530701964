<template>
    <base-sticky-heading
        :title="$t('settingsOpeningBalances.general.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    v-if="$acl.can('delete_opening_balances')"
                    size="sm"
                    outline
                    variant="danger"
                    icon="line-icons:general:trash-01"
                    @click="state.showDeleteModal = true"
                >
                    {{ $t('general.delete') }}
                </base-button>

                <base-button
                    size="sm"
                    @click="
                        $router.push({
                            name: 'settings.opening-balances.update',
                        })
                    "
                >
                    {{ $t('general.edit') }}
                </base-button>
            </div>
        </template>

        <data-grid
            v-if="accounts"
            :columns="columns"
            :data-source="accounts"
            custom-classes="rounded-none border-r-0 border-l-0 border-b-0"
        >
            <template #column-name="{ item }">
                {{ item.name }}
            </template>

            <template #column-debit="{ item }">
                <span v-if="item?.opening_balance_journal_line?.debit">
                    {{
                        $filters.numberFormat(
                            item?.opening_balance_journal_line?.debit
                        )
                    }}
                </span>
            </template>

            <template #column-credit="{ item }">
                <span v-if="item?.opening_balance_journal_line?.credit">
                    {{
                        $filters.numberFormat(
                            item?.opening_balance_journal_line?.credit
                        )
                    }}
                </span>
            </template>
        </data-grid>

        <div
            class="flex flex-col justify-end border border-x-0 border-warning-300 bg-warning-25 py-6"
        >
            <div class="grid grid-cols-[1fr_160px_160px]">
                <div class="flex flex-col text-end text-md">
                    <p
                        class="px-6 text-end text-sm font-semibold uppercase text-warning-700"
                    >
                        {{ $t('settingsOpeningBalances.general.total') }}
                    </p>
                </div>
                <p class="flex px-6 text-sm text-warning-700">
                    {{ $filters.numberFormat(summary.total_debit) }}
                </p>
                <p class="flex px-6 text-sm text-warning-700">
                    {{ $filters.numberFormat(summary.total_credit) }}
                </p>
            </div>
        </div>
    </base-sticky-heading>

    <base-delete-modal
        size="md"
        :show="state.showDeleteModal"
        :loading="loadingDelete"
        @modal-close="state.showDeleteModal = false"
        @confirm-delete="confirmDelete"
    >
        <template #title>
            {{ $t('settingsOpeningBalances.deleteModal.title') }}
        </template>
        <template #description>
            {{ $t('settingsOpeningBalances.deleteModal.description') }}
        </template>
    </base-delete-modal>

    <opening-balances-save-success-modal
        :show="$route.query.is_success"
        @modal-close="$router.replace({ query: {} })"
    />
</template>

<script setup>
const { t } = useI18n()
const columns = [
    {
        property: 'name',
        label: t('settingsOpeningBalances.table.account'),
    },
    {
        property: 'debit',
        label: t('settingsOpeningBalances.table.debit'),
        headerCellClass: 'w-40',
    },
    {
        property: 'credit',
        label: t('settingsOpeningBalances.table.credit'),
        headerCellClass: 'w-40',
    },
]

const { execute: getBalances } = useApi('/api/opening-balances', 'GET')

const accounts = ref([])
const { errorNotify, successNotify } = useNotification()
const emitter = useEmitter()

const summary = reactive({
    total_debit: 0,
    total_credit: 0,
})
onMounted(async () => {
    await initData()
})

const initData = async () => {
    try {
        emitter.emit('set-loading', true)

        const { chart_of_accounts, total_credit, total_debit } =
            await getBalances()

        if (chart_of_accounts) {
            accounts.value = chart_of_accounts
        }

        summary.total_debit = total_debit
        summary.total_credit = total_credit
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}

const state = reactive({
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false,
})

const { execute: executeDelete, loading: loadingDelete } = useApi(
    '/api/opening-balances',
    'DELETE'
)

const confirmDelete = async () => {
    try {
        await executeDelete()

        successNotify({
            text: t('settingsOpeningBalances.general.deleteSuccess'),
        })

        state.showDeleteModal = false

        await initData()
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    }
}
</script>
