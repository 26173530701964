<template>
    <base-sticky-heading
        :title="$t('settingsCurrencies.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    v-if="$acl.can('create_currencies')"
                    variant="primary"
                    icon="line-icons:general:plus"
                    @click="addCurrency"
                >
                    {{ $t('settingsCurrencies.addTextCurrency') }}
                </base-button>
            </div>
        </template>

        <data-grid
            :data-source="entities.data"
            :columns="columns"
            :loading="loadingSearch"
            :empty-text="$t('settingsCurrencies.list.emptyText')"
            :custom-classes="'border-t-none border-l-none border-r-none rounded-none'"
        >
            <template #column-name="{ item }">
                <span class="text-primary-600">{{
                    item.iso + ' - ' + item.name
                }}</span>

                <base-badge
                    v-if="item.is_default"
                    :label="t('settingsCurrencies.list.defaultCurrency')"
                    variant="success"
                    @click="onClickBaseCurrency"
                />
            </template>

            <template #column-action="{ item }">
                <base-button
                    v-if="$acl.can('update_currencies')"
                    size="sm"
                    variant="tertiary-gray"
                    icon="line-icons:editor:pencil-01"
                    @click="editCurrency(item)"
                />

                <base-button
                    v-if="!item.is_default && $acl.can('delete_currencies')"
                    icon="line-icons:general:trash-01"
                    variant="tertiary-gray"
                    size="sm"
                    @click="toggleModalDelete(item)"
                />
            </template>

            <template #footer>
                <grid-pagination
                    :total="entities.last_page"
                    :page="entities.current_page"
                />
            </template>
        </data-grid>

        <settings-currencies-delete-modal
            v-if="showModalDelete"
            :currency-id="currency?.id"
            :is-prevent-delete="currency?.is_assigned"
            :show="showModalDelete"
            @modal-close="toggleModalDelete(null)"
            @refresh="executeRefreshSearch"
            size="md"
        />

        <settings-currencies-create
            v-if="showModalAdd"
            :show="showModalAdd"
            @modal-close="showModalAdd = false"
            @refresh="executeRefreshSearch"
        />

        <settings-currencies-edit
            v-if="showModalEdit"
            :currency-id="currency?.id"
            :show="showModalEdit"
            @modal-close="onCloseEditCurrency"
            @refresh="executeRefreshSearch"
        />
    </base-sticky-heading>
</template>

<script setup>
import { isEmpty } from 'lodash-es'

const { t } = useI18n()

const {
    entities,
    setEntity,
    entity: currency,
    useApiSearch,
} = useApiFactory('currencies')

const { loading: loadingSearch, executeRefreshSearch } = useApiSearch()

useHead({ title: t('settingsCurrencies.title') })

const showModalDelete = ref(false)
const showModalAdd = ref(false)
const showModalEdit = ref(false)
const selectedCurrencyId = ref(null)

const router = useRouter()

const columns = [
    {
        property: 'name',
        label: t('settingsCurrencies.list.name'),
    },
    {
        property: 'symbol',
        label: t('settingsCurrencies.list.symbol'),
    },
]

const onClickBaseCurrency = () => {
    router.push({
        name: 'settings.company',
    })
}

const addCurrency = () => {
    showModalAdd.value = true
}

const editCurrency = (entity) => {
    showModalEdit.value = true
    setEntity(entity)
}

const onCloseEditCurrency = () => {
    showModalEdit.value = false
    selectedCurrencyId.value = null
}

const toggleModalDelete = (entity = null) => {
    showModalDelete.value = !isEmpty(entity)
    setEntity(entity)
}
</script>
