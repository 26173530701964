<template>
    <base-section :title="$t('settingsCompany.address.title')">
        <template #description>
            <span class="text-sm font-normal text-gray-500">
                {{ $t('settingsCompany.address.firstDescription') }}
            </span>
            <span class="text-sm font-normal text-gray-500">
                {{ $t('settingsCompany.address.secondDescription') }}
            </span>
        </template>
        <template #right>
            <div class="grid grid-cols-2 gap-x-3 gap-y-4">
                <form-text-input
                    :label="$t('settingsCompany.address.companyNameLabel')"
                    :error-message="errorCompanyName"
                    v-model="companyName"
                />
                <form-text-input
                    :label="$t('settingsCompany.address.legalCompanyNameLabel')"
                    :error-message="errorLegalCompanyName"
                    v-model="legalCompanyName"
                />
                <form-text-input
                    :label="$t('settingsCompany.address.ceoDirectorLabel')"
                    :error-message="errorCeoDirector"
                    v-model="ceoDirector"
                />
                <form-single-select
                    :label="$t('settingsCompany.address.typeOfCompanyLabel')"
                    :options="companyTypes"
                    :error-message="errorTypeCompany"
                    v-model="typeCompany"
                    option-value="id"
                    option-label="name"
                />
                <form-text-input
                    :label="$t('settingsCompany.address.address1Label')"
                    :error-message="errorAddress1"
                    class="col-span-2"
                    v-model="address1"
                />
                <form-text-input
                    :label="$t('settingsCompany.address.address2Label')"
                    :error-message="errorAddress2"
                    class="col-span-2"
                    v-model="address2"
                />
                <form-text-input
                    :label="$t('settingsCompany.address.postcodeLabel')"
                    :error-message="errorPostcode"
                    v-model="postcode"
                />
                <form-text-input
                    :label="$t('settingsCompany.address.cityLabel')"
                    :error-message="errorCity"
                    v-model="city"
                />
                <form-single-select
                    class="col-span-2"
                    :label="$t('settingsCompany.address.countryLabel')"
                    :options="countries"
                    :error-message="errorCountry"
                    v-model="country"
                />
                <form-single-select
                    :label="$t('settingsCompany.address.industryLabel')"
                    :options="companyIndustries"
                    :error-message="errorIndustry"
                    v-model="industry"
                    option-value="id"
                    option-label="name"
                    :searchable-fields="['name']"
                />
                <form-single-select
                    :label="
                        $t('settingsCompany.address.numberOfCoWorkersLabel')
                    "
                    :options="coWorkersOptions"
                    :error-message="errorCoWorkers"
                    v-model="coWorkers"
                />
            </div>
        </template>
    </base-section>
</template>

<script setup>
import { NUMBER_OF_COWORKERS } from '@tenant/utils/constants'

const { value: companyName, errorMessage: errorCompanyName } =
    useField('company_name')
const { value: legalCompanyName, errorMessage: errorLegalCompanyName } =
    useField('company_name_legal')
const { value: ceoDirector, errorMessage: errorCeoDirector } =
    useField('director_name')
const { value: typeCompany, errorMessage: errorTypeCompany } =
    useField('company_type_id')
const { value: address1, errorMessage: errorAddress1 } = useField('address1')
const { value: address2, errorMessage: errorAddress2 } = useField('address2')
const { value: postcode, errorMessage: errorPostcode } = useField('postcode')
const { value: city, errorMessage: errorCity } = useField('city')
const { value: country, errorMessage: errorCountry } = useField('country_id')
const { value: industry, errorMessage: errorIndustry } = useField(
    'company_industry_id'
)
const { value: coWorkers, errorMessage: errorCoWorkers } =
    useField('co_workers')

const { companyIndustries } = useCompanyIndustry()
const { countries } = useCountries()
const { companyTypes } = useCompanyType()

const coWorkersOptions = NUMBER_OF_COWORKERS.map((number) => ({
    value: number,
    label: number,
}))
</script>
