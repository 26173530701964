<template>
    <base-modal close @modal-close="closeModal" :show="show" size="xl">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('settingsSystem.numberRange.edit.title') }}
            </span>
        </template>

        <base-section
            :title="$t('settingsSystem.numberRange.edit.formatLabel')"
        >
            <template #description>
                <span class="text-sm font-normal text-gray-500">
                    {{
                        $t('settingsSystem.numberRange.edit.formatDescription')
                    }}
                </span>
                <span class="text-sm font-normal text-gray-500">
                    {{
                        $t('settingsSystem.numberRange.edit.nextDigitLabel', {
                            format: SEQUENCE.NUMBER,
                        })
                    }}
                </span>
                <span class="text-sm font-normal text-gray-500">
                    {{
                        $t('settingsSystem.numberRange.edit.currentYearLabel', {
                            format: SEQUENCE.YYYY,
                            digit: $filters.now().format('YYYY'),
                        })
                    }}
                </span>
                <span class="text-sm font-normal text-gray-500">
                    {{
                        $t('settingsSystem.numberRange.edit.currentYearLabel', {
                            format: SEQUENCE.YY,
                            digit: $filters.now().format('YY'),
                        })
                    }}
                </span>
                <span class="text-sm font-normal text-gray-500">
                    {{
                        $t(
                            'settingsSystem.numberRange.edit.currentMonthLabel',
                            {
                                format: SEQUENCE.MM,
                                digit: $filters.now().format('MM'),
                            }
                        )
                    }}
                </span>
                <span class="text-sm font-normal text-gray-500">
                    {{
                        $t(
                            'settingsSystem.numberRange.edit.currentMonthLabel',
                            {
                                format: SEQUENCE.M,
                                digit: $filters.now().format('M'),
                            }
                        )
                    }}
                </span>
                <span class="text-sm font-normal text-gray-500">
                    {{
                        $t('settingsSystem.numberRange.edit.currentDayLabel', {
                            format: SEQUENCE.DD,
                            digit: $filters.now().format('DD'),
                        })
                    }}
                </span>
                <span class="text-sm font-normal text-gray-500">
                    {{
                        $t('settingsSystem.numberRange.edit.currentDayLabel', {
                            format: SEQUENCE.D,
                            digit: $filters.now().format('D'),
                        })
                    }}
                </span>
            </template>
            <template #right>
                <form-text-input
                    v-model="format"
                    :disabled="saveLoading"
                    :error-message="errorFormat"
                />
            </template>
        </base-section>

        <base-section
            :title="$t('settingsSystem.numberRange.edit.nextNumberLabel')"
        >
            <template #right>
                <form-text-input
                    v-model="nextNumber"
                    type="number"
                    :disabled="saveLoading"
                    :error-message="errorNextNumber"
                />
            </template>
        </base-section>

        <base-section
            :title="$t('settingsSystem.numberRange.edit.previewLabel')"
        >
            <template #right>
                <form-text-input disabled :model-value="preview" />
            </template>
        </base-section>

        <template #modal-footer>
            <div class="flex justify-end gap-3">
                <base-button
                    outline
                    variant="default"
                    @click="closeModal"
                    :disabled="saveLoading"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    type="submit"
                    :loading="saveLoading"
                    @click="onSubmit"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { useField, useForm } from 'vee-validate'
import { SEQUENCE } from '@tenant/utils/constants'
import {
    useSequence,
    useEmitter,
    useApiFactory,
    useNotification,
} from '@tenant/composables'

const { t } = useI18n()
const emitter = useEmitter()

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    rangeId: {
        type: String,
        required: true,
    },
})
const emit = defineEmits(['modal-close', 'modal-save'])

const { handleSubmit, setValues, setErrors } = useForm()

const { value: format, errorMessage: errorFormat } = useField('format')
const { value: nextNumber, errorMessage: errorNextNumber } =
    useField('next_number')

const { preview } = useSequence(nextNumber, format)
const { successNotify } = useNotification()

const { useApiGet, useApiSave } = useApiFactory('sequence-settings')
const { execute: executeGet } = useApiGet()
const { execute: executeSave, loading: saveLoading } = useApiSave()

useHead({
    title: t('settingsSystem.numberRange.edit.title'),
})

watch(
    () => props.rangeId,
    async () => {
        if (!props.rangeId) return

        emitter.emit('set-loading', true)

        const { next_number, format } = await executeGet(props.rangeId)

        setValues({
            format,
            next_number,
        })

        emitter.emit('set-loading', false)
    },
    { immediate: true }
)

const onSubmit = handleSubmit((values) =>
    executeSave(values)
        .then(() => {
            successNotify({
                title: t('general.success'),
                text: t('settingsSystem.numberRange.message.editSuccess'),
            })

            emit('modal-save')
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
)

const closeModal = () => {
    emit('modal-close')
}
</script>
