<template>
    <base-sticky-heading
        :title="$t('settingsInvoice.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    @click="onSaveSetting"
                >
                    {{ $t('settingsInvoice.saveBtn') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <base-section
                :title="$t('settingsInvoice.reminders.title')"
                :description="$t('settingsInvoice.reminders.description')"
            >
                <template #right>
                    <form-toggle
                        v-model="invoiceReminderEnabled"
                        size="md"
                        :label="$t('settingsInvoice.reminders.enableReminders')"
                    />

                    <div
                        class="mt-6 flex gap-2.5"
                        :class="{
                            'pointer-events-none opacity-50':
                                !invoiceReminderEnabled,
                        }"
                    >
                        <div
                            v-for="{ value, key } in reminders"
                            :key="key"
                            class="flex flex-col items-center gap-2.5 rounded-md border border-gray-300 bg-white p-6 hover:bg-gray-50"
                        >
                            <form-toggle
                                size="sm"
                                :label="
                                    $t(
                                        'settingsInvoice.reminders.reminderDays',
                                        value.dayAfter
                                    )
                                "
                                :model-value="value.enabled"
                            />

                            <div
                                class="cursor-pointer text-sm text-primary-700"
                                @click="onOpenTemplateModal(key, value)"
                            >
                                {{
                                    $t(
                                        'settingsInvoice.reminders.editTemplateBtn'
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </template>
            </base-section>
        </div>

        <settings-invoices-reminder-template-modal
            :show="showTemplateModal"
            :template-type="textTemplateType"
            @modal-close="showTemplateModal = false"
            @modal-confirm="showTemplateModal = false"
            :template-key="editedTemplateKey"
        />
    </base-sticky-heading>
</template>

<script setup>
import { sortBy } from 'lodash-es'
import { useFieldArray } from 'vee-validate'

const emitter = useEmitter()
const { errorNotify, successNotify } = useNotification()
const { t } = useI18n()
useHead({
    title: t('settingsInvoice.title'),
})

const { handleSubmit, setValues } = useForm()
const { value: invoiceReminderEnabled } = useField('reminderEnabled')
const { fields: reminders } = useFieldArray('reminders')

const { settings } = useSettings([
    'invoice.reminder.enabled',
    'invoice.reminder.firstReminder',
    'invoice.reminder.secondReminder',
    'invoice.reminder.thirdReminder',
])

watch(
    () => settings.value,
    (data) => {
        if (!data) return

        setValues({
            reminderEnabled: data?.['invoice.reminder.enabled']?.value,
            reminders: sortBy(
                [
                    {
                        key: 'invoice.reminder.firstReminder',
                        ...(data?.['invoice.reminder.firstReminder']?.value ??
                            {}),
                    },
                    {
                        key: 'invoice.reminder.secondReminder',
                        ...(data?.['invoice.reminder.secondReminder']?.value ??
                            {}),
                    },
                    {
                        key: 'invoice.reminder.thirdReminder',
                        ...(data?.['invoice.reminder.thirdReminder']?.value ??
                            {}),
                    },
                ],
                'dayAfter'
            ),
        })
    },
    { immediate: true }
)

const showTemplateModal = ref(false)
const editedTemplateKey = ref(null)
const onOpenTemplateModal = (key) => {
    showTemplateModal.value = true
    editedTemplateKey.value = key
}

const { execute: executeSaveConfig } = useApi('/api/settings', 'POST')
const onSaveSetting = handleSubmit(async () => {
    try {
        emitter.emit('set-loading', true)

        await executeSaveConfig({
            data: {
                payload: [
                    {
                        key: 'invoice.reminder.enabled',
                        value: {
                            value: invoiceReminderEnabled.value,
                        },
                    },
                    ...reminders.value.map(({ value: { key, ...data } }) => {
                        return {
                            key,
                            value: {
                                value: data,
                            },
                        }
                    }),
                ],
            },
        })
        successNotify({
            text: t('settingsInvoice.saveSuccess'),
        })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const textTemplateType = ref(null)
const { textTemplateTypes } = useTextTemplateTypes()
watch(
    () => textTemplateTypes.value,
    (data) => {
        textTemplateType.value =
            data.find((item) => item.technical_name === 'document')?.id ?? ''
    },
    { immediate: true }
)
</script>
