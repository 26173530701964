<template>
    <base-section :title="$t('settingsCompany.contact.title')">
        <template #description>
            <span class="text-sm font-normal text-gray-500">
                {{ $t('settingsCompany.contact.description') }}
            </span>
        </template>
        <template #right>
            <div class="grid grid-cols-2 gap-x-3 gap-y-4">
                <form-text-input
                    :label="$t('settingsCompany.contact.phoneLabel')"
                    :error-message="errorPhone"
                    v-model="phone"
                />
                <form-text-input
                    :label="$t('settingsCompany.contact.faxLabel')"
                    :error-message="errorFax"
                    v-model="fax"
                />
                <form-text-input
                    :label="$t('settingsCompany.contact.emailLabel')"
                    :error-message="errorEmail"
                    class="col-span-2"
                    v-model="email"
                />
                <form-text-input
                    :label="$t('settingsCompany.contact.webLabel')"
                    :error-message="errorWeb"
                    class="col-span-2"
                    v-model="web"
                />
            </div>
        </template>
    </base-section>
</template>

<script setup>
import { useField } from 'vee-validate'
const { value: phone, errorMessage: errorPhone } = useField('phone')
const { value: fax, errorMessage: errorFax } = useField('fax')
const { value: email, errorMessage: errorEmail } = useField('email')
const { value: web, errorMessage: errorWeb } = useField('web_address')
</script>
