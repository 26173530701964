<template>
    <form @submit.prevent="onSubmit">
        <base-sticky-heading
            :title="$t('settingsUsers.invite.title')"
            :description="$t('settingsUsers.invite.description')"
            class="min-h-[82px] items-center"
        >
            <template #right>
                <div class="flex gap-3">
                    <base-button
                        outline
                        variant="default"
                        :disabled="loadingInvite"
                        @click="onCancel"
                    >
                        {{ $t('general.cancel') }}
                    </base-button>
                    <base-button
                        type="submit"
                        variant="primary"
                        icon="line-icons:communication:mail-01"
                        :loading="loadingInvite"
                    >
                        {{ $t('general.invite') }}
                    </base-button>
                </div>
            </template>

            <div class="px-8 py-6">
                <base-section
                    :title="$t('settingsUsers.invite.emailSection.title')"
                    :description="
                        $t('settingsUsers.invite.emailSection.description')
                    "
                >
                    <template #right>
                        <form-field-array
                            :fields="fields"
                            :label-add="$t('settingsUsers.invite.addEmail')"
                            :min="1"
                            item-class="!items-start"
                            @on-remove-item="remove"
                            @on-add-item="onAddItem"
                        >
                            <template
                                #default="{ item: { isFirst, value, key } }"
                            >
                                <form-control-group-row class="items-start">
                                    <form-field-array-error
                                        :name="`emails[${key}].email`"
                                        #="{ errorMessage }"
                                    >
                                        <form-text-input
                                            v-model="value.email"
                                            placeholder="name@example.com"
                                            :label="isFirst && 'user.email'"
                                            :error-message="errorMessage"
                                        />
                                    </form-field-array-error>

                                    <form-field-array-error
                                        :name="`emails[${key}].roleId`"
                                        #="{ errorMessage }"
                                    >
                                        <form-single-select
                                            v-model="value.roleId"
                                            :label="isFirst && 'general.role'"
                                            :options="userRoles"
                                            :error-message="errorMessage"
                                        />
                                    </form-field-array-error>
                                </form-control-group-row>
                            </template>

                            <template
                                #delete-button="{
                                    isReachMin,
                                    onRemoveItem,
                                    idx,
                                    item: { isFirst },
                                }"
                            >
                                <base-button
                                    outline
                                    size="xl"
                                    icon="line-icons:general:trash-01"
                                    class="!rounded-full border-dashed !p-3 text-gray-400"
                                    :class="isFirst && 'mt-7'"
                                    variant="gray"
                                    :disabled="isReachMin"
                                    @click="onRemoveItem(idx)"
                                />
                            </template>
                        </form-field-array>
                    </template>
                </base-section>
            </div>
        </base-sticky-heading>
    </form>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { useFieldArray, useForm } from 'vee-validate'
import { useApi, useNotification } from '@tenant/composables'

const { t } = useI18n()
const router = useRouter()
const { successNotify } = useNotification()

useHead({
    title: t('settingsUsers.invite.title'),
})

const userRoles = ref([])

const initialValues = {
    emails: [{ email: null, roleId: null }],
}

const { setErrors, handleSubmit, resetForm } = useForm({
    initialValues,
})
const { remove, push, fields } = useFieldArray('emails')

const { execute: executeInviteUser, loading: loadingInvite } = useApi(
    'api/users-invites',
    'POST'
)
const { execute: executeGetRoles } = useApi('api/users/list/roles', 'GET')

onMounted(() => {
    getListRoles()
})

const getListRoles = () =>
    executeGetRoles().then((roles) => {
        userRoles.value = roles.map(({ name, display_name }) => ({
            value: name,
            label: display_name,
        }))
    })

const onAddItem = () => {
    push({ email: null, type: null })
}

const onCancel = () => {
    router.push({
        name: 'settings.users.list',
    })
}

const onSubmit = handleSubmit((values) => {
    executeInviteUser({ data: values })
        .then(() => {
            successNotify({
                title: t('settingsUsers.invite.inviteSuccess'),
            })

            resetForm({
                values: initialValues,
            })
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
})
</script>
