<template>
    <base-modal
        size="sxl"
        close
        @modal-close="closeModal"
        :show="show"
        has-separator
    >
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                {{ $t('settingsInvoice.templateModal.title') }}
            </span>
        </template>

        <form-control-group>
            <form-control-group-row class="items-center">
                {{ $t('settingsInvoice.templateModal.sendReminderFirstText') }}
                <form-number-input
                    class="!w-[52px]"
                    size="sm"
                    v-model="fields[templateKey].value.dayAfter"
                />
                {{ $t('settingsInvoice.templateModal.sendReminderSecondText') }}
            </form-control-group-row>

            <form-control-group-row>
                <form-text-input
                    :label="$t('settingsInvoice.templateModal.subjectLabel')"
                    :placeholder="
                        $t('settingsInvoice.templateModal.subjectPlaceholder')
                    "
                    v-model="fields[templateKey].value.subject"
                />
            </form-control-group-row>

            <form-control-group-row>
                <form-text-template-editor
                    :label="$t('settingsInvoice.templateModal.messageLabel')"
                    :placeholder="
                        $t('settingsInvoice.templateModal.messagePlaceholder')
                    "
                    :template-type="templateType"
                    v-model="fields[templateKey].value.body"
                />
            </form-control-group-row>

            <form-control-group-row>
                <base-alert
                    class="w-full"
                    variant="primary"
                    :title="
                        $t(
                            'settingsInvoice.templateModal.attachmentAlert.title'
                        )
                    "
                    :description="
                        $t(
                            'settingsInvoice.templateModal.attachmentAlert.description'
                        )
                    "
                />
            </form-control-group-row>
        </form-control-group>

        <template #modal-footer>
            <div class="flex gap-3">
                <base-button variant="default" @click="closeModal" full-width>
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button variant="primary" @click="onConfirm" full-width>
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const { fields } = useFieldArray('reminders')

defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    templateType: {
        type: [String, null],
        required: true,
    },
    templateKey: {
        type: [Number, null],
        required: true,
    },
})

const emit = defineEmits(['modal-close', 'modal-confirm'])

const closeModal = () => {
    emit('modal-close')
}

const onConfirm = async () => {
    emit('modal-confirm')
}
</script>
