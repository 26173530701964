<template>
    <base-modal @modal-close="closeModal" :show="show" size="sm">
        <template #modal-icon>
            <div class="flex">
                <base-feature-icon
                    variant="success"
                    name="line-icons:general:check-circle"
                />
            </div>
        </template>

        <div class="flex flex-col gap-2">
            <h5 class="text-lg font-medium text-gray-900">
                {{ $t('settingsOpeningBalances.successModal.title') }}
            </h5>

            <p class="text-sm text-gray-500">
                {{ $t('settingsOpeningBalances.successModal.noteText') }}
            </p>

            <ul class="list-disc pl-4 text-sm text-gray-500">
                <li>
                    {{
                        $t(
                            'settingsOpeningBalances.successModal.firstDescription'
                        )
                    }}
                </li>
                <li>
                    {{
                        $t(
                            'settingsOpeningBalances.successModal.secondDescription'
                        )
                    }}
                </li>
                <li>
                    {{
                        $t(
                            'settingsOpeningBalances.successModal.thirdDescription'
                        )
                    }}
                </li>
            </ul>
        </div>

        <template #modal-footer>
            <div class="flex justify-end gap-4">
                <base-button full-width @click="closeModal">
                    {{ $t('general.ok') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
defineProps({
    show: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits(['modal-close'])

const closeModal = () => {
    emit('modal-close')
}
</script>
