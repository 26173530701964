<template>
    <button @click.prevent="closeModal" class="absolute right-3 top-3">
        <base-icon name="line-icons:general:x" size="lg" variant="gray" />
    </button>
    <base-feature-icon
        variant="danger"
        name="line-icons:files:folder"
        :class="'absolute left-3 top-3'"
    />

    <div class="grid w-full grid-cols-[auto,1fr] gap-3 rounded-lg p-4 pl-12">
        <div class="pl-8 pr-3 pt-2">
            <div class="pb-2 text-sm font-medium text-gray-900">
                {{ $t('settingsCurrencies.list.deletePrevent.title') }}
            </div>

            <div class="text-sm font-light text-gray-500">
                {{ $t('settingsCurrencies.list.deletePrevent.description') }}
            </div>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['close'])

const closeModal = () => {
    emit('close')
}
</script>
