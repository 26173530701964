<template>
    <base-modal
        close
        @modal-close="closeModal"
        :show="show"
        size="lg"
        has-separator
    >
        <template #modal-header>
            <h4 class="mb-1 text-lg font-medium text-gray-900">
                {{ $t('settingsOpeningBalances.migrationDateModal.title') }}
            </h4>

            <p class="mb-6 text-sm text-gray-600">
                {{
                    $t('settingsOpeningBalances.migrationDateModal.description')
                }}
            </p>
        </template>

        <div class="min-h-[280px]">
            <div class="mb-5 flex gap-4">
                <form-single-select
                    :options="monthOptions"
                    v-model="date.month"
                    :label="
                        $t(
                            'settingsOpeningBalances.migrationDateModal.monthLabel'
                        )
                    "
                    :show-clear="false"
                />
                <form-single-select
                    :options="yearOptions"
                    v-model="date.year"
                    :label="
                        $t(
                            'settingsOpeningBalances.migrationDateModal.yearLabel'
                        )
                    "
                    :show-clear="false"
                />
            </div>

            <base-alert
                class="mb-8"
                v-if="migrationDate"
                variant="warning"
                :description="
                    $t(
                        'settingsOpeningBalances.migrationDateModal.migrationDate',
                        {
                            date: migrationDate,
                        }
                    )
                "
            >
                <template #description>
                    <span
                        class="text-sm font-normal text-warning-600"
                        v-html="
                            $t(
                                'settingsOpeningBalances.migrationDateModal.migrationDate',
                                {
                                    date: migrationDate,
                                }
                            )
                        "
                    >
                    </span>
                </template>
            </base-alert>
        </div>

        <template #modal-footer>
            <div class="flex justify-end gap-4">
                <base-button variant="default" outline @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button @click="onConfirm">
                    {{ $t('general.confirm') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import dayjs from 'dayjs'
import { getDate, now } from '@tenant/utils/day'
import { DATE_HUMAN_FORMAT, DATE_SERVER_FORMAT } from '@tenant/utils/constants'

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    initialDate: {
        type: String,
        default: '',
    },
})
const emit = defineEmits(['modal-close', 'modal-confirm'])

const closeModal = () => {
    emit('modal-close')
}

const date = reactive({
    month: getDate(props.initialDate).month(),
    year: getDate(props.initialDate).year(),
})

const { t } = useI18n()
const monthOptions = [
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.jan'),
        value: 0,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.feb'),
        value: 1,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.mar'),
        value: 2,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.apr'),
        value: 3,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.may'),
        value: 4,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.jun'),
        value: 5,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.jul'),
        value: 6,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.aug'),
        value: 7,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.sep'),
        value: 8,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.oct'),
        value: 9,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.nov'),
        value: 10,
    },
    {
        label: t('settingsOpeningBalances.migrationDateModal.monthOptions.dec'),
        value: 11,
    },
]

const yearOptions = computed(() => {
    // Show 10 years before the current year and + 1 year of the current year.
    const currentYear = now().year()
    const startYear = currentYear - 10
    const endYear = currentYear + 2
    return [].concat(
        Array.from(
            { length: endYear - startYear },
            (_, i) => startYear + i
        ).map((year) => ({
            label: year,
            value: year,
        }))
    )
})

const selectedDate = computed(() => {
    return dayjs().month(date?.month).year(date?.year).startOf('month')
})

const migrationDate = computed(() => {
    if (!date) return

    return selectedDate.value.subtract(1, 'day').format(DATE_HUMAN_FORMAT)
})

const onConfirm = () => {
    emit(
        'modal-confirm',
        selectedDate.value.subtract(1, 'day').format(DATE_SERVER_FORMAT)
    )
}
</script>
