<template>
    <base-heading
        :title="
            $t('settingsUsers.edit.title', {
                firstName: firstName,
                lastName: lastName,
            })
        "
        :description="$t('settingsUsers.edit.description')"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    :loading="loadingSave"
                    @click="onSubmit"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-heading>

    <settings-users-section-general disabled />
    <settings-users-section-account disabled />
    <settings-users-section-role v-if="!isOwner" />
</template>

<script setup>
const { user } = useAuth()
const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const emitter = useEmitter()
const { successNotify } = useNotification()

const { setValues, setErrors, handleSubmit } = useForm()
const { value: firstName } = useField('first_name')
const { value: lastName } = useField('last_name')

const { useApiGet, useApiSave } = useApiFactory('users')
const { execute: executeGet } = useApiGet()
const { loading: loadingSave, execute: executeSave } = useApiSave()

useHead({ title: t('settingsUsers.edit.title') })

const isOwner = computed(() => user.value.id === route.params.id)

onMounted(() => {
    emitter.emit('set-loading', true)

    executeGet(route.params.id).then((user) => {
        setValues({
            ...user,
            roles: user.roles.map((i) => i.name),
        })
    })

    emitter.emit('set-loading', false)
})

const onCancel = () => {
    router.push({
        name: 'settings.users.list',
    })
}

const onSubmit = handleSubmit((values) => {
    executeSave(values, route.params.id)
        .then(() => {
            successNotify({
                title: t('general.success'),
                text: t('settingsUsers.message.editSuccess'),
            })
            router.push({
                name: 'settings.users.list',
            })
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
})
</script>
