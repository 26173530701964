<template>
    <div>
        <base-section
            :title="$t('settingsSystem.numberRange.title')"
            :description="$t('settingsSystem.numberRange.description')"
            class="px-6 py-8"
        >
            <template #right>
                <data-grid
                    :data-source="entities.data"
                    :columns="columns"
                    :loading="loadingSearch"
                    :empty-text="$t('settingsSystem.numberRange.emptyText')"
                    :empty-sub="$t('settingsSystem.numberRange.emptySubText')"
                    identify-field="id"
                >
                    <template #column-action="{ item }">
                        <base-button
                            v-if="$acl.can('update_sequence_settings')"
                            icon="line-icons:general:edit-01"
                            variant="tertiary-gray"
                            size="sm"
                            @click="openEditModal(item)"
                        />
                        <base-tooltip
                            dark
                            hover
                            :content="
                                $t(
                                    'settingsSystem.numberRange.tooltip.sequenceDeleteDisableLabel'
                                )
                            "
                        >
                            <base-button
                                v-if="$acl.can('delete_sequence_settings')"
                                icon="line-icons:general:trash-01"
                                variant="tertiary-gray"
                                size="sm"
                                disabled
                            />
                        </base-tooltip>
                    </template>

                    <template #column-type="{ item }">
                        {{ item.sequence_type?.name }}
                    </template>

                    <template #column-next_sequence="{ item }">
                        {{
                            $filters.formatSequence(
                                item.next_number,
                                item.format
                            )
                        }}
                    </template>

                    <template #footer>
                        <grid-pagination
                            :total="entities.last_page"
                            :page="entities.current_page"
                        />
                    </template>
                </data-grid>
            </template>
        </base-section>

        <settings-system-number-ranges-edit-modal
            :show="showEditModal"
            :range-id="editEntity?.id ?? ''"
            @modal-close="showEditModal = false"
            @modal-save="onRangeUpdated"
        />
    </div>
</template>
<script setup>
const { t } = useI18n()

const { entities, useApiSearch } = useApiFactory('sequence-settings')
const { loading: loadingSearch, executeRefreshSearch } = useApiSearch({
    filter: {
        'sequenceType.technical_name': [
            'in',
            [
                'contact',
                'invoice',
                'manual_journal',
                'estimate',
                'credit_note',
                'vendor_credit',
            ],
        ],
    },
})

useHead({
    title: t('settingsSystem.numberRange.list.title'),
})

const columns = computed(() => [
    {
        property: 'format',
        label: t('settingsSystem.numberRange.list.formatColumn'),
    },
    {
        property: 'type',
        label: t('settingsSystem.numberRange.list.typeColumn'),
    },
    {
        property: 'next_number',
        label: t('settingsSystem.numberRange.list.nextNumberColumn'),
    },
    {
        property: 'next_sequence',
        label: t('settingsSystem.numberRange.list.nextSequenceColumn'),
    },
])

const showEditModal = ref(false)
const editEntity = ref(null)
const openEditModal = (item) => {
    editEntity.value = item
    showEditModal.value = true
}

const onRangeUpdated = () => {
    showEditModal.value = false
    executeRefreshSearch()
}
</script>
