<template>
    <base-section :title="$t('settingsCompany.logo.title')">
        <template #description>
            <span class="text-sm font-normal text-gray-500">
                {{ $t('settingsCompany.logo.description') }}
            </span>
        </template>
        <template #right>
            <div class="max-w-md">
                <form-simple-upload
                    v-model="value"
                    :error-message="errorMessage"
                    :max-files="1"
                    :accepted-files="['image/*']"
                    :description="$t('settingsCompany.logo.uploadDescription')"
                    :custom-path="customFilePath"
                    :custom-name="customFileName"
                />
            </div>
        </template>
    </base-section>
</template>
<script setup>
const { value, errorMessage } = useField('logo')

const customFileName = (file) => {
    return file.full_name
}

const customFilePath = (file) => {
    return file.preview?.path
}
</script>
