<template>
    <base-sticky-heading
        v-if="!goToConfirm"
        :title="$t('settingsOpeningBalances.general.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    size="sm"
                    outline
                    variant="danger"
                    @click="isShowMigrationDate = true"
                >
                    {{ $t('settingsOpeningBalances.general.migrationDateBtn') }}
                </base-button>

                <base-button
                    :disabled="!summaryData.totalAmount"
                    size="sm"
                    @click="onContinue"
                >
                    {{ $t('settingsOpeningBalances.general.continueBtn') }}
                </base-button>
            </div>
        </template>

        <div class="p-6" v-if="result">
            <base-accordion
                v-for="type in Object.keys(result.accounts)"
                :key="type"
                class="mb-2.5 rounded-lg border border-gray-200"
                :icon-bordered="false"
                trigger-classes="rounded-lg p-4 bg-gray-25 justify-between"
                ref="accountRefs"
                :id="type"
            >
                <template #title>
                    <p class="mr-1 text-md text-gray-700">
                        {{ $t('general.coaTypes', { type }) }}
                    </p>
                </template>

                <template #icon="{ isOpen }">
                    <base-icon
                        v-if="isOpen"
                        name="line-icons:arrows:chevron-down"
                        size="sm"
                        variant="inherit"
                    />
                    <base-icon
                        v-else
                        name="line-icons:arrows:chevron-right"
                        size="sm"
                        variant="inherit"
                    />
                </template>

                <template #content>
                    <data-grid
                        v-if="fields.length"
                        :columns="columns"
                        :data-source="getChildrenFormFields(type)"
                        custom-classes="rounded-none border-r-0 border-l-0 border-b-0"
                    >
                        <template #column-name="{ item: { value } }">
                            {{ value.name }}
                        </template>

                        <template #column-balance="{ item: { value } }">
                            {{ value.closing_balance || '-' }}
                            <base-badge
                                v-if="value.closing_balance"
                                variant="gray"
                                :label="
                                    value.closing_balance > 0
                                        ? $t('general.dr')
                                        : $t('general.cr')
                                "
                            />
                        </template>

                        <template #column-debit="{ item: { value } }">
                            <form-number-input
                                v-model="value.debit"
                                @update:model-value="
                                    value.debit > 0 ? (value.credit = 0) : ''
                                "
                                :precision="2"
                                size="sm"
                            />
                        </template>

                        <template #column-credit="{ item: { value } }">
                            <form-number-input
                                v-model="value.credit"
                                @update:model-value="
                                    value.credit > 0 ? (value.debit = 0) : ''
                                "
                                hide-message
                                :precision="2"
                                size="sm"
                            />
                        </template>
                    </data-grid>

                    <base-button
                        class="m-6"
                        size="sm"
                        outline
                        variant="default"
                        icon="line-icons:general:plus-circle"
                        @click="openAddAccountModal(type)"
                    >
                        {{
                            $t('settingsOpeningBalances.general.addNewAccount')
                        }}
                    </base-button>
                </template>
            </base-accordion>
        </div>

        <div
            class="m-6 mt-0 flex flex-col justify-end rounded-lg border border-warning-300 bg-warning-25 p-6"
        >
            <div
                class="grid grid-cols-[1fr_160px_160px] gap-2.5 border-b border-warning-200 pb-6"
            >
                <p
                    class="text-end text-sm font-semibold uppercase text-warning-700"
                >
                    {{ $t('settingsOpeningBalances.general.total') }}
                </p>
                <p
                    class="flex justify-end text-sm font-semibold text-warning-700"
                >
                    {{ $filters.numberFormat(totalDebit) }}
                </p>
                <p
                    class="flex justify-end text-sm font-semibold text-warning-700"
                >
                    {{ $filters.numberFormat(totalCredit) }}
                </p>

                <div class="flex flex-col text-end text-md">
                    <p class="text-sm font-semibold text-danger-500">
                        {{
                            $t(
                                'settingsOpeningBalances.summary.firstSection.title'
                            )
                        }}
                    </p>
                    <p class="text-xs text-warning-600">
                        {{
                            $t(
                                'settingsOpeningBalances.summary.firstSection.description'
                            )
                        }}
                    </p>
                </div>
                <p
                    class="flex justify-end text-sm font-semibold text-danger-500"
                >
                    {{
                        difference >= 0 ? $filters.numberFormat(difference) : ''
                    }}
                </p>

                <p
                    class="flex justify-end text-sm font-semibold text-danger-500"
                >
                    {{
                        difference < 0
                            ? $filters.numberFormat(Math.abs(difference))
                            : ''
                    }}
                </p>
            </div>

            <div class="grid grid-cols-[1fr_160px_160px] gap-2.5 pt-6">
                <div class="flex flex-col text-end text-md">
                    <p
                        class="text-end text-sm font-semibold uppercase text-warning-700"
                    >
                        {{
                            $t(
                                'settingsOpeningBalances.summary.secondSection.title'
                            )
                        }}
                    </p>
                    <p class="text-xs text-warning-600">
                        {{
                            $t(
                                'settingsOpeningBalances.summary.secondSection.description'
                            )
                        }}
                    </p>
                </div>
                <p
                    class="flex justify-end text-sm font-semibold text-warning-700"
                >
                    {{ $filters.numberFormat(totalAmount) }}
                </p>
                <p
                    class="flex justify-end text-sm font-semibold text-warning-700"
                >
                    {{ $filters.numberFormat(totalAmount) }}
                </p>
            </div>
        </div>
    </base-sticky-heading>

    <opening-balances-confirm
        v-else
        @cancel="onCancel"
        @confirm="onConfirm"
        :data="summaryData"
    />

    <bookeeping-accounts-add-modal
        :type="selectedType"
        :show="!isNil(selectedType)"
        @modal-close="onCloseAddModal"
        @on-finished="onFinished"
    />

    <opening-balances-migration-date-modal
        v-if="initialMigrationDate"
        :show="isShowMigrationDate"
        :initial-date="initialMigrationDate"
        @modal-close="isShowMigrationDate = false"
        @modal-confirm="onSetMigrationDate"
    />

    <opening-balances-difference-warning-modal
        v-if="difference"
        :show="showConfirmModal"
        :difference="difference"
        @modal-close="showConfirmModal = false"
        @modal-confirm="onWarningConfirmed"
    />
</template>

<script setup>
import { AMOUNT_TYPE, DATE_SERVER_FORMAT } from '@tenant/utils/constants'
import { now } from '@tenant/utils/day'
import { isNil } from 'lodash-es'

const { t } = useI18n()
const emitter = useEmitter()

useHead({
    title: t('settingsOpeningBalances.general.title'),
})

const { errorNotify } = useNotification()

const selectedType = ref(null)
const initialMigrationDate = ref(null)
const { execute: getBalances, result } = useApi(
    '/api/opening-balances/updatable-list',
    'GET'
)

onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        await getBalances()

        initialMigrationDate.value = result.value.migration_date
        Object.values(result.value.accounts).forEach((accountsType) => {
            accountsType.forEach((account) => {
                push({
                    type: account.type,
                    parent_id: account.parent_id,
                    closing_balance: account.closing_balance,
                    chart_of_account_id: account.id,
                    name: account.name,
                    debit: account?.opening_balance_journal_line?.debit ?? 0,
                    credit: account?.opening_balance_journal_line?.credit ?? 0,
                })
            })
        })
    } catch ({ errorMessage }) {
        errorNotify({
            title: t('general.error'),
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const { handleSubmit } = useForm()
const { setValue: setMigrationDate } = useField('migration_date', undefined, {
    initialValue: now().format(DATE_SERVER_FORMAT),
})
const { push, fields } = useFieldArray('account_items')

const columns = [
    {
        property: 'name',
        label: t('settingsOpeningBalances.table.account'),
        headerCellClass: 'bg-transparent',
        dataCellClass: '!py-1.5',
    },
    {
        property: 'balance',
        label: t('settingsOpeningBalances.table.balance'),
        headerCellClass: 'bg-transparent',
        dataCellClass: '!py-1.5',
    },
    {
        property: 'debit',
        label: t('settingsOpeningBalances.table.debit'),
        headerCellClass: 'w-40 bg-transparent',
        dataCellClass: '!py-1.5',
    },
    {
        property: 'credit',
        label: t('settingsOpeningBalances.table.credit'),
        headerCellClass: 'w-40 bg-transparent',
        dataCellClass: '!py-1.5',
    },
]

const getChildrenFormFields = (type) => {
    return fields.value.filter(({ value }) => value.type === type)
}

const { totalNet: totalDebit } = useNetGross(fields, AMOUNT_TYPE.NET, 'debit')
const { totalNet: totalCredit } = useNetGross(fields, AMOUNT_TYPE.NET, 'credit')

const difference = computed(() => {
    return totalCredit.value - totalDebit.value
})

const totalAmount = computed(() => {
    return Math.max(totalCredit.value, totalDebit.value)
})
const summaryData = computed(() => ({
    totalDebit: totalDebit.value,
    totalCredit: totalCredit.value,
    difference: difference.value,
    totalAmount: totalAmount.value,
}))

// HANDLE CONFIRM BUTTON
const showConfirmModal = ref(false)
const goToConfirm = ref(false)
const onContinue = () => {
    if (difference.value) {
        showConfirmModal.value = true
    } else {
        goToConfirm.value = true
    }
}
const onWarningConfirmed = () => {
    goToConfirm.value = true
    showConfirmModal.value = false
}
// END: HANDLE CONFIRM BUTTON

const onCloseAddModal = () => {
    selectedType.value = null
}

const openAddAccountModal = (type) => {
    selectedType.value = type
}

const onFinished = (chartOfAccount) => {
    // Set height to auto to show the content
    const accordion = accountRefs.value.find(
        (acc) => acc.$el.id === selectedType.value
    )
    if (accordion) {
        accordion.contentContainer.style.height = 'auto'
    }
    selectedType.value = null
    push({
        type: chartOfAccount.type,
        parent_id: chartOfAccount.ancestors[0].id,
        chart_of_account_id: chartOfAccount.id,
        name: chartOfAccount.name,
        debit: 0,
        credit: 0,
    })
}

const onCancel = () => {
    goToConfirm.value = false
}

const router = useRouter()
const { execute: saveBalances } = useApi('/api/opening-balances', 'POST')
const onConfirm = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)

        const balances =
            values?.account_items.filter(({ debit, credit }) => {
                return debit || credit
            }) ?? []

        await saveBalances({
            data: {
                balances,
                migration_date: values.migration_date,
            },
        })

        await router.push({
            name: 'settings.opening-balances.list',
            query: {
                is_success: true,
            },
        })
    } catch ({ errorMessage }) {
        errorNotify({
            title: t('general.error'),
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const isShowMigrationDate = ref(false)
const onSetMigrationDate = (date) => {
    setMigrationDate(date)
    isShowMigrationDate.value = false
}

const accountRefs = ref([])
</script>
