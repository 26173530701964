<template>
    <base-sticky-heading
        :title="$t('settingsOpeningBalances.general.title')"
        class="py-6"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    size="sm"
                    outline
                    variant="danger"
                    @click="$emit('cancel')"
                >
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button size="sm" @click="$emit('confirm')">
                    {{ $t('general.confirm') }}
                </base-button>
            </div>
        </template>

        <data-grid
            v-if="fields.length"
            :columns="columns"
            :data-source="accounts"
            custom-classes="rounded-none border-r-0 border-l-0 border-b-0"
        >
            <template #column-name="{ item: { value } }">
                {{ value.name }}
            </template>

            <template #column-debit="{ item: { value } }">
                <span v-if="value.debit">
                    {{ $filters.numberFormat(value.debit) }}
                </span>
            </template>

            <template #column-credit="{ item: { value } }">
                <span v-if="value.credit">
                    {{ $filters.numberFormat(value.credit) }}
                </span>
            </template>
        </data-grid>

        <div
            class="flex flex-col justify-end border border-x-0 border-warning-300 bg-warning-25 py-6"
        >
            <div class="grid grid-cols-[1fr_160px_160px]">
                <div class="flex flex-col text-end text-md">
                    <p
                        class="px-6 text-end text-sm font-semibold uppercase text-warning-700"
                    >
                        {{ $t('settingsOpeningBalances.general.total') }}
                    </p>
                </div>
                <p class="flex px-6 text-sm text-warning-700">
                    {{ $filters.numberFormat(data.totalAmount) }}
                </p>
                <p class="flex px-6 text-sm text-warning-700">
                    {{ $filters.numberFormat(data.totalAmount) }}
                </p>
            </div>
        </div>
    </base-sticky-heading>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
})

defineEmits(['cancel', 'confirm'])

const { fields } = useFieldArray('account_items')
const accounts = computed(() => {
    const data = fields.value.filter(({ value }) => {
        return value.debit || value.credit
    })

    if (props.data.difference) {
        data.push({
            value: {
                name: 'Opening balance adjustment',
                debit: props.data.difference >= 0 ? props.data.difference : 0,
                credit:
                    props.data.difference < 0
                        ? Math.abs(props.data.difference)
                        : 0,
            },
        })
    }

    return data
})

const { t } = useI18n()
const columns = [
    {
        property: 'name',
        label: t('settingsOpeningBalances.table.account'),
    },
    {
        property: 'debit',
        label: t('settingsOpeningBalances.table.debit'),
        headerCellClass: 'w-40',
    },
    {
        property: 'credit',
        label: t('settingsOpeningBalances.table.credit'),
        headerCellClass: 'w-40',
    },
]
</script>
