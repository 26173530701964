<template>
    <div class="flex h-full">
        <div class="flex w-72 flex-col border-r border-gray-200 bg-gray-25">
            <settings-sidebar />
        </div>

        <div class="flex flex-1 flex-col overflow-auto">
            <router-view />
        </div>
    </div>
</template>
<script setup>
const { t } = useI18n()

useHead({
    title: t('accountantSetting.title'),
})
</script>
