<template>
    <base-section
        :title="$t('settingsUsers.form.roleSection.title')"
        :description="$t('settingsUsers.form.roleSection.description')"
    >
        <template #right>
            <form-control-group-row>
                <form-multi-select
                    v-model="roles"
                    :label="$t('settingsUsers.form.roles')"
                    :options="userRoles"
                    :disabled="disabled"
                    :error-message="errorRoles"
                />
            </form-control-group-row>
        </template>
    </base-section>
</template>

<script setup>
defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { value: roles, errorMessage: errorRoles } = useField('roles')

const userRoles = ref([])

const { execute: executeGetRoles } = useApi('api/users/list/roles', 'GET')

onMounted(() => {
    getListRoles()
})

const getListRoles = () =>
    executeGetRoles().then((roles) => {
        userRoles.value = roles.map(({ name }) => ({
            value: name,
            label: name,
        }))
    })
</script>
