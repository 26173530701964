<template>
    <base-modal
        close
        :show="show"
        size="md"
        @modal-close="closeModal"
        :content-padding="false"
        :loading="loading"
    >
        <template #modal-header>
            <h3 class="text-2xl font-semibold">
                {{ $t('settingsCurrencies.create.title') }}
            </h3>
        </template>

        <settings-currencies-form />

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button
                    full-width
                    variant="primary"
                    :loading="loading"
                    @click="onSubmit"
                >
                    {{ $t('settingsCurrencies.create.btnAdd') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const { t } = useI18n()
const emit = defineEmits(['modal-close', 'refresh'])
const emitter = useEmitter()

const { setErrors, handleSubmit } = useForm()
const { successNotify, errorNotify } = useNotification()

const { loading, execute } = useApiFactory('currencies').useApiCreate()

defineProps({
    show: {
        type: Boolean,
        required: true,
    },
})

const onSubmit = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)
        await execute(values)

        successNotify({
            title: t('general.success'),
            text: t('settingsCurrencies.create.successMessage'),
        })

        emit('refresh')
        emit('modal-close')
    } catch ({ errors }) {
        setErrors(errors)
        errorNotify({
            title: t('general.error'),
            text: t('settingsCurrencies.create.errorMessage'),
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const closeModal = () => {
    emit('modal-close')
}
</script>
