import { GUARD } from '@tenant/utils/constants'
const routes = [
    {
        name: 'settings',
        path: '/settings',
        component: () => import('./pages/settings-index.vue'),
        redirect: {
            name: 'settings.company',
        },
        children: [
            {
                name: 'settings.company',
                path: '/settings/company',
                component: () => import('./pages/settings-company.vue'),
                meta: {
                    permission: 'view_companies',
                },
            },
            {
                name: 'settings.invoices',
                path: '/settings/invoices',
                component: () => import('./pages/settings-invoices.vue'),
                meta: {
                    permission: 'view_user_settings',
                },
            },
            {
                name: 'settings.opening-balances',
                path: '/settings/opening-balances',
                redirect: {
                    name: 'settings.opening-balances.list',
                },
                meta: {
                    permission: 'view_opening_balances',
                },
                children: [
                    {
                        name: 'settings.opening-balances.list',
                        path: '',
                        component: () =>
                            import('./pages/opening-balances-list.vue'),
                    },
                    {
                        name: 'settings.opening-balances.update',
                        path: 'update',
                        component: () =>
                            import('./pages/opening-balances-update.vue'),
                        meta: {
                            permission: 'update_opening_balances',
                        },
                    },
                ],
            },
            {
                name: 'settings.users',
                path: '/settings/users',
                redirect: {
                    name: 'settings.users.list',
                },
                meta: {
                    permission: 'view_user_settings',
                },
                children: [
                    {
                        name: 'settings.users.list',
                        path: '',
                        component: () => import('./pages/users-list.vue'),
                    },
                    {
                        name: 'settings.users.invite',
                        path: 'invite',
                        component: () => import('./pages/users-invite.vue'),
                    },
                    {
                        name: 'settings.users.edit',
                        path: ':id',
                        component: () => import('./pages/users-edit.vue'),
                        meta: {
                            permission: 'update_user_settings',
                        },
                    },
                    {
                        name: 'settings.roles.create',
                        path: 'roles/create',
                        component: () => import('./pages/roles-create.vue'),
                        meta: {
                            permission: 'update_user_settings',
                        },
                    },
                    {
                        name: 'settings.roles.edit',
                        path: 'roles/edit/:id',
                        component: () => import('./pages/roles-edit.vue'),
                        meta: {
                            permission: 'update_user_settings',
                        },
                    },
                ],
            },
            {
                name: 'settings.currencies',
                path: '/settings/currencies',
                redirect: {
                    name: 'settings.currencies.list',
                },
                meta: {
                    permission: 'view_currencies',
                },
                children: [
                    {
                        name: 'settings.currencies.list',
                        path: 'list',
                        component: () =>
                            import('./pages/settings-currencies-list.vue'),
                    },
                ],
            },
            {
                name: 'settings.text-templates',
                path: '/settings/text-templates',
                redirect: {
                    name: 'settings.text-templates.list',
                },
                meta: {
                    permission: 'view_text_templates',
                },
                children: [
                    {
                        name: 'settings.text-templates.list',
                        path: 'list',
                        component: () =>
                            import('./pages/settings-text-templates-list.vue'),
                    },
                    {
                        name: 'settings.text-templates.create',
                        path: 'create',
                        component: () =>
                            import(
                                './pages/settings-text-templates-create.vue'
                            ),
                        meta: {
                            permission: 'create_text_templates',
                        },
                    },
                    {
                        name: 'settings.text-templates.edit',
                        path: 'edit/:id',
                        component: () =>
                            import('./pages/settings-text-templates-edit.vue'),
                        meta: {
                            permission: 'update_text_templates',
                        },
                    },
                ],
            },
            {
                name: 'settings.taxRules.list',
                path: 'tax',
                component: () => import('./pages/tax-rule-list.vue'),
                meta: {
                    permission: 'view_tax_rules',
                },
            },
            {
                name: 'settings.system',
                path: '/settings/system',
                component: () => import('./pages/settings-system.vue'),
            },
        ],
    },
]
export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_companies',
    },
}
