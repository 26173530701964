<template>
    <base-modal
        close
        @modal-close="closeModal"
        :show="show"
        :size="size"
        :show-footer="!isShowAlert"
        :show-header="!isShowAlert"
        :customContentClass="{
            '!py-0': isShowAlert,
            '!px-0': isShowAlert,
        }"
    >
        <settings-currencies-prevent-delete-modal
            v-if="isShowAlert"
            @close="closeModal"
        />

        <template #modal-icon v-if="!isShowAlert">
            <div class="flex">
                <base-feature-icon
                    variant="danger"
                    name="line-icons:general:trash-01"
                />
            </div>
        </template>
        <div class="flex flex-col" v-if="!isShowAlert">
            <h5 class="pb-5 text-lg font-medium text-gray-900">
                {{ $t('settingsCurrencies.list.deleteConfirmation') }}
            </h5>
        </div>

        <template #modal-footer v-if="!isShowAlert">
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    :disabled="loading"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    full-width
                    variant="danger"
                    :loading="loading"
                    @click="onDelete"
                >
                    {{ $t('settingsCurrencies.list.btnDelete') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>
<script setup>
const emit = defineEmits(['modal-close', 'refresh'])
const { t } = useI18n()

const props = defineProps({
    currencyId: {
        type: String,
        required: true,
    },
    show: {
        type: [Boolean, Number, String],
        default: false,
    },

    size: {
        type: String,
        default: 'sm',
    },
})

const { useApiDelete } = useApiFactory('currencies')
const { execute, loading } = useApiDelete()
const { errorNotify, successNotify } = useNotification()

const isShowAlert = ref(false)

const onDelete = async () => {
    try {
        await execute(props.currencyId)

        successNotify({
            title: t('general.success'),
            text: t('settingsCurrencies.delete.successMessage'),
        })

        emit('refresh')
        emit('modal-close')
    } catch ({ hasErrors, errorMessage }) {
        isShowAlert.value = true

        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    }
}

const closeModal = () => {
    emit('modal-close')
}
</script>
