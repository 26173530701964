<template>
    <base-section
        :title="$t('settingsSystem.closeBooks.title')"
        :description="$t('settingsSystem.closeBooks.description')"
    >
        <template #right>
            <div class="flex items-start">
                <div class="flex w-64 flex-col gap-3.5">
                    <form-toggle
                        v-model="closeBookEnable"
                        :label="$t('settingsSystem.closeBooks.closeTheBooks')"
                    />
                    <div class="flex min-h-20 items-end">
                        <form-date-picker
                            v-show="closeBookEnable"
                            v-model="closeBookDate"
                            single
                            :label="$t('settingsSystem.closeBooks.closingDate')"
                            size="sm"
                        />
                    </div>
                </div>
            </div>
        </template>
    </base-section>
</template>

<script setup>
const { value: closeBookEnable } = useField('closeBookEnable')
const { value: closeBookDate, setValue: setCloseBookDate } =
    useField('closeBookDate')

watch(
    () => closeBookEnable.value,
    (value) => {
        if (value) {
            return
        }

        setCloseBookDate(null)
    }
)
</script>
