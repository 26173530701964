export default {
    settings: {
        title: 'Settings',
        tabs: {
            profile: 'Organization Profile',
            system: 'System Settings',
            openingBalances: 'Opening Balances',
            users: 'Users & Permissions',
            currencies: 'Currencies',
            invoices: 'Invoice Settings',
            textTemplates: 'Text Templates',
            taxRules: 'Tax Rules',
        },
        successMessage: 'Settings saved successfully',
    },
    settingsInvoice: {
        title: 'Invoice Settings',
        saveBtn: 'Save Settings',
        saveSuccess: 'Invoice settings saved successfully',
        reminders: {
            title: 'Reminders',
            description:
                'Cybooks will automatically send reminders do your customers if an invoice has not been paid yet.',
            enableReminders: 'Enable invoice reminders',
            editTemplateBtn: 'Edit template',
            reminderDays: '{n} day later | {n} day later | {n} days later',
        },
        templateModal: {
            title: 'Edit Reminder',
            sendReminderFirstText: 'Send a reminder',
            sendReminderSecondText: 'days before due date',
            subjectLabel: 'Subject *',
            subjectPlaceholder: 'Enter subject',
            messageLabel: 'Message',
            messagePlaceholder: 'Enter message',
            attachmentAlert: {
                title: 'Invoice attachment',
                description:
                    'We’ll automatically attach the invoice as an attachment to the reminder email.',
            },
        },
    },
    settingsCompany: {
        title: 'Company profile',
        description: 'Update your company photo and details here.',

        sidebar: {
            label: 'Company',
        },

        address: {
            title: 'Address',
            firstDescription:
                'This will be used as your default business address.',
            secondDescription: 'It will appear in the footer of the invoice.',
            companyNameLabel: 'Company name *',
            legalCompanyNameLabel: 'Legal company name',
            ceoDirectorLabel: 'CEO / Director',
            typeOfCompanyLabel: 'Type of company',
            address1Label: 'Address 1',
            address2Label: 'Address 2',
            postcodeLabel: 'Postcode',
            cityLabel: 'City',
            countryLabel: 'Country',
            industryLabel: 'Industry',
            numberOfCoWorkersLabel: 'Number of co-workers',
        },

        tax: {
            title: 'Tax- and register numbers',
            description:
                'For a proper business document, you must at least include your tax number.',
            districtCourtLabel: 'District court',
            companyRegistrationLabel: 'Company registration number',
            vatIdLabel: 'VAT ID',
            taxReferenceLabel: 'T.I.C. (Tax Identification Code)',
            taxRateLabel: 'Tax rate',
            cashBasisLabel: 'Cash basis - on payment date',
            accountingMethodLabel: 'Accounting method',
            methodOfCalculationLabel: 'Method of calculation Income STMT',
            priceLabel: 'Prices on documents ',
            netPricesLabel: 'Net-Prices',
            netPricesDescription: 'Prices on documents are without vat',
            grossPricesLabel: 'Gross-Prices',
            grossPricesDescription: 'Prices on documents include vat',
        },

        contact: {
            title: 'Contact information',
            description:
                'Be available for possible questions of your customers.',
            phoneLabel: 'Phone',
            faxLabel: 'FAX',
            emailLabel: 'Email',
            webLabel: 'Web domain',
        },

        payment: {
            title: 'Payment information',
            firstDescription:
                'Enter your bank information so that your bills can be paid.',
            secondDescription:
                'You want your PayPal address, directly on your invoice? Then add it here.',
            bankNameLabel: 'Bank name',
            accountNumberLabel: 'Account number',
            sortCodeLabel: 'Sort code',
            ibanLabel: 'IBAN',
            swiftCodeLabel: 'Swift Code',
        },

        logo: {
            title: 'Upload Logo',
            description:
                'Upload your personal logo in .png, .jpg or .pdf format.',
            uploadDescription: 'SVG, PNG, JPG or GIF (max. 800x400px)',
        },

        message: {
            updateSuccessfully: 'Update the profile of company successfully',
        },
    },
    settingsOpeningBalances: {
        sidebar: {
            settingsLabel: 'Opening Balances',
        },
        general: {
            title: 'Opening Balances',
            migrationDateBtn: 'Migration Date',
            continueBtn: 'Continue',
            addNewAccount: 'Add new account',
            total: 'Total',
            asset: 'Asset',
            liability: 'Liability',
            equity: 'Equity',
            income: 'Income',
            expense: 'Expense',
            'non-expense': 'Non-Expense',
            cancel: 'Cancel',
            confirm: 'Confirm',
            saveSuccess: 'Opening balances saved successfully',
            deleteSuccess: 'Opening balances deleted successfully',
        },
        summary: {
            firstSection: {
                title: 'Opening Balance Adjustments',
                description:
                    'This account will hold the difference in credit and debit.',
            },
            secondSection: {
                title: 'Total amount',
                description: 'Includes Opening Balance Adjustment account',
            },
        },
        table: {
            account: 'ACCOUNT',
            balance: 'AVAILABLE BALANCE',
            debit: 'DEBIT',
            credit: 'CREDIT',
        },
        warningModal: {
            description:
                'The total debits and credits differ by {amount}. You can go back and adjust the balances to remove the difference, or you can continue and the difference will be transferred to the Opening Balance Adjustment account.',
            goBackBtn: 'Go back',
            continueBtn: 'Continue',
        },
        successModal: {
            title: 'Opening Balances has been saved!',
            noteText: 'NOTE',
            firstDescription:
                'Ensure that all the new transactions that you create are after the Migration Date.',
            secondDescription:
                'You can change the Migration Date at any time. However, you must ensure that the account balances as on the new migration date are correct.',
            thirdDescription:
                'Also you can record, edit or delete transactions that are on or pre-dated to the migration date. Cybooks will list them in the Opening Balances page and you can click Sync toupdate the opening balances with those transactions.',
        },
        deleteModal: {
            title: 'Delete Opening Balances',
            description: 'Do you want to delete the entered opening balances?',
        },
        migrationDateModal: {
            title: 'Migration Date',
            description:
                'Enter the date that you began processing all your transactions in Cybooks. It’s easiest when you set your migration date to be the start of a Tax period.',
            continueBtn: 'Continue',
            migrationDate:
                'For this migration date you need to enter opening balances as at: <strong>{date}</strong>',
            monthLabel: 'Month',
            yearLabel: 'Year',
            monthOptions: {
                jan: 'January',
                feb: 'February',
                mar: 'March',
                apr: 'April',
                may: 'May',
                jun: 'June',
                jul: 'July',
                aug: 'August',
                sep: 'September',
                oct: 'October',
                nov: 'November',
                dec: 'December',
            },
        },
    },
    settingsUsers: {
        sidebar: {
            settingsLabel: 'Settings',
            label: 'Users',
        },

        list: {
            title: 'Users & Permissions',
            description:
                'Manage your users and their account permissions here.',
            name: 'Name',
            email: 'Email',
            userType: 'User Type',
            status: 'Status',
            dateAdded: 'Date added',
            adminUser: 'Admin users',
            invited: 'Invited',
            deleteConfirmation: 'Enter the first name to confirm',
            adminSection: {
                title: 'Users',
                inviteBtn: 'Invite user',
            },
            accountingFirms: {
                title: 'Accountant',
                inviteBtn: 'Invite accountant',
                inviteTooltip: 'You can only invite one accountant',
            },
            roles: {
                list: {
                    title: 'Roles',
                    createBtn: 'Create role',
                    columnName: 'Name',
                    columnDescription: 'Description',
                },
                deleteSuccess: '{name} deleted successfully',
                cannotEditTooltip: 'This role cannot be edited',
            },
            invitationSection: {
                title: 'Inviting emails',
                description: 'Emails that we have sent invitations',
            },
        },

        invite: {
            title: 'Invite users',
            description:
                'You can invite new users and assign to a specific role. ',
            emailSection: {
                title: 'Users email addresses',
                description:
                    'Enter user email addresses and click invite button to send invitation emails.',
            },
            addEmail: 'Add email',
            inviteSuccess: 'Invite succeeded',
        },

        edit: {
            title: 'Edit {firstName} {lastName}',
            description:
                'You can create a new user and assign to a specific role.',
        },

        form: {
            salutation: 'Salutation *',
            firstName: 'First name *',
            lastName: 'Last name *',
            abbreviation: 'Abbreviation',
            email: 'Email *',
            roles: 'Roles *',
            language: 'Language',
            admin: 'Admin',
            auditor: 'Auditor',

            generalSection: {
                title: 'General information',
                description:
                    'You can create a new user and assign to a specific role.',
            },
            accountSection: {
                title: 'Account information',
                description:
                    'The user wil get an confirmation email to be able to login.',
            },
            roleSection: {
                title: 'User Roles',
                description:
                    "A user role is given to each person to define what they can and can't do.",
            },
        },

        message: {
            editSuccess: 'Save user success',
        },

        createRole: {
            title: 'Create Role',
            saveBtn: 'Save Role',
            basicInfo: 'Basic information',
            nameLabel: 'Name *',
            namePlaceholder: 'Enter name',
            descriptionLabel: 'Description *',
            descriptionPlaceholder: 'Enter description',
            permissionsLabel: 'Permissions',
            grid: {
                permissions: 'Permissions',
                view: 'View',
                create: 'Create',
                update: 'Update',
                delete: 'Delete',
                all: 'All',
            },
            createSuccess: 'Role created successfully',
            permissionError: 'Please set the permission for this role',
        },
        editRole: {
            title: 'Edit Role',
            successMessage: 'Role updated successfully',
        },
    },
    settingsCurrencies: {
        base_currency_note: 'Amount is displayed in your base currency',
        sidebar: {
            label: 'Currencies',
        },
        title: 'Currencies',
        addTextCurrency: 'New Currency',
        list: {
            name: 'Name',
            symbol: 'Symbol',
            defaultCurrency: 'Base Currency',
            emptyText: 'No currencies found',
            deleteConfirmation: 'Are you sure about deleting this currency?',
            btnDelete: 'Yes, delete',
            deletePrevent: {
                title: 'We can’t delete this currency',
                description:
                    'This currency cannot be deleted because it is associated with contacts or documents.',
            },
        },
        delete: {
            successMessage: 'Currency deleted successfully',
            errorMessage: 'Error deleting currency',
        },
        create: {
            title: 'Create currency',
            iso: 'Currency Code',
            name: 'Currency Name',
            symbol: 'Currency Symbol',
            decimalPlaces: 'Decimal places',
            format: 'Format',
            btnAdd: 'Add Currency',
            successMessage: 'Currency created successfully',
            errorMessage: 'Error creating currency',
        },
        modalPlaceholder: {
            title: 'Select placeholder',
        },
        modalTemplate: {
            title: 'Select template',
        },
        edit: {
            title: 'Edit currency',
            btnSave: 'Update Currency',
            successMessage: 'Currency updated successfully',
            errorMessage: 'Error updating currency',
        },
    },
    settingsTextTemplates: {
        sidebar: {
            label: 'Text Templates',
        },
        title: 'Text template',
        description:
            'Manage the text templates of your invoices & e-mail templates',
        addTextTemplate: 'Add text template',
        editBtn: 'Edit',
        deleteBtn: 'Delete',
        general: {
            sectionTitle: 'General information',
            sectionDescription:
                'Manage the text templates of your invoices & e-mail templates',
            nameLabel: 'Name*',
            nameHelpText: 'Name of text template for faster recognition later',
            typeLabel: 'Type*',
            typeHelpText: 'Type of text template where do you want to use',
            userLabel: 'User for',
            lineItemLabel: 'Line item',
            languageLabel: 'Languages*',
            textTemplateLabel: 'Text template*',
            addItem: 'Add item',
            placeholderLabel: 'Placeholder',
            templateLabel: 'Template',
        },
        list: {
            emptyText: 'No text template found',
            emptySubText: 'Create your first text template',
            name: 'Name',
            type: 'Type',
            preview: 'Text preview',
            standard: 'Standard',
            deleteConfirmation: 'Password',
            languages: 'Languages',
        },
        create: {
            title: 'Create text template',
            description:
                'Manage the text templates of your invoices & e-mail templates',
            saveSuccessMsg: 'Text template has been created successfully',
        },
        modalPlaceholder: {
            title: 'Select placeholder',
        },
        modalTemplate: {
            title: 'Select template',
        },
        edit: {
            title: 'Edit text template',
            description:
                'Manage the text templates of your invoices & e-mail templates',
            saveSuccessMsg: 'Text template has been updated successfully',
        },
    },
    settingsTaxRules: {
        list: {
            title: 'Tax rules',
            addRule: 'Add tax rule',
            nameColumn: 'Name',
            taxRateColumn: 'Tax rate',
            deleteConfirmation: 'Enter the tax rule name to confirm',
            itemUpdate: 'Update',
            itemDelete: 'Delete',
        },
        upsert: {
            title: 'Add New Tax Rate',
            displayName: 'Tax Rate Display Name',
            displayNameHint:
                'The name as you would like it to appear in Cybooks (limited to 50 characters)',
            taxType: 'Tax Type',
            taxTypeHint:
                'Choose how this tax rate will be reported in you  VAT return',
            vatRate: 'VAT Rate',
            successMessage: 'VAT rule saved successfully',
        },
    },
    settingsSystem: {
        title: 'System Settings',
        save: 'Save Settings',
        documentExtraction: {
            title: 'Document Extraction',
            description:
                'Our document extraction uses AI to extract and match information from the documents to Cybooks.',
            modelTrainingLabel: 'Use documents for model training',
            modelTrainingDescription:
                'Activate this option to allow us to use your documents to improve our models',
        },
        closeBooks: {
            title: 'Close Books',
            description:
                "When you're ready, close your books to prevent unwanted changes before filing your taxes. This locks your books so no one can edit your accounting data before the closing date.",
            closeTheBooks: 'Close the books',
            closingDate: 'Closing date',
        },
        cloudStorage: {
            google: {
                headline: 'Google Drive',
                description:
                    'We will sync the documents with Cybooks. If a file is stored in Drive, we will start the document recognition.',
                buttonConnect: 'Connect Google Drive',
                buttonDisconnect: 'Disconnect Google Drive',
                buttonConfigBill: 'Config Bill',
                chooseFolder: 'Choose folder to sync',
            },
            billFolderModal: {
                title: 'Select folder',
                description:
                    'Please select the folder where you’re storing all your bills. We will use only the files within this folder to process your bills.',
                selectFolder: 'Select folder',
                billFolder: 'Bill Folder',
            },
        },
        numberRange: {
            title: 'Number Range Settings',
            description:
                'A number range is used for invoices, estimates and other entities which increases automatically.',
            emptyText: 'No number range found',
            emptySubText: 'Create your first number range',
            list: {
                title: 'Sequence',
                emptyText: 'No sequence found',
                emptySubText: 'Create your first sequence',
                formatColumn: 'Format',
                typeColumn: 'Type',
                nextNumberColumn: 'Next number',
                nextSequenceColumn: 'Next sequence',
            },
            edit: {
                title: 'Edit Sequence Setting',
                formatLabel: 'Format',
                formatDescription: 'The following variables are available:',
                nextDigitLabel: '{format} - Next digit',
                currentYearLabel: '{format} - Current year ({digit})',
                currentMonthLabel: '{format} - Current month ({digit})',
                currentDayLabel: '{format} - Current day ({digit})',
                nextNumberLabel: 'Next number',
                previewLabel: 'Preview',
            },
            tooltip: {
                sequenceDeleteDisableLabel: 'A sequence can’t be deleted',
            },
            message: {
                editSuccess: 'Save sequence success',
            },
        },
    },
}
