<template>
    <base-sticky-heading
        :title="$t('settingsUsers.list.title')"
        class="min-h-[82px] items-center"
    >
        <div class="px-8 py-6">
            <div class="mb-5">
                <settings-users-list-admin />
            </div>

            <settings-users-list-accountant class="mb-5" />

            <settings-users-role-list />
        </div>
    </base-sticky-heading>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'

const { t } = useI18n()

useHead({ title: t('settingsUsers.list.title') })
</script>
