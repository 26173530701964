<template>
    <base-modal
        close
        @modal-close="closeModal"
        :show="show"
        size="lg"
        :has-separator="true"
        :loading="loading"
    >
        <template #modal-header>
            <span class="block pb-5 text-lg font-semibold text-gray-900">
                {{ $t('settingsTaxRules.upsert.title') }}
            </span>
        </template>
        <div class="flex flex-col gap-6">
            <div class="grid grid-cols-7 gap-6">
                <form-text-input
                    v-if="entity && !entity.editable"
                    :model-value="entity?.tax_type.name"
                    class="col-span-5"
                    :label="$t('settingsTaxRules.upsert.taxType')"
                    :hint-text="$t('settingsTaxRules.upsert.taxTypeHint')"
                    :disabled="true"
                />
                <form-single-select
                    v-else
                    v-model="taxType"
                    class="col-span-5"
                    :show-clear="false"
                    :options="taxTypes"
                    option-label="name"
                    option-value="id"
                    :label="$t('settingsTaxRules.upsert.taxType')"
                    :hint-text="$t('settingsTaxRules.upsert.taxTypeHint')"
                    :error-message="taxTypeError"
                />

                <form-number-input
                    v-model="taxRate"
                    class="col-span-2"
                    :label="$t('settingsTaxRules.upsert.vatRate')"
                    :precision="2"
                    input-class="pr-16"
                    :disabled="entity && !entity.editable"
                    :error-message="taxRateError"
                >
                    <template #right>
                        <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center border-l border-gray-300 px-4"
                        >
                            %
                        </div>
                    </template>

                    <template #tooltip>
                        <div />
                    </template>
                </form-number-input>
            </div>
            <form-text-input
                v-model="name"
                :label="$t('settingsTaxRules.upsert.displayName')"
                :hint-text="$t('settingsTaxRules.upsert.displayNameHint')"
                :error-message="nameError"
            />
        </div>
        <template #modal-footer>
            <div class="mt-2 flex justify-end gap-3">
                <base-button outline variant="default" @click="closeModal">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button @click="onSave">
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'on-save'])

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    entity: {
        type: Object,
        required: false,
    },
})

const { t } = useI18n()
const { successNotify } = useNotification()

const { useApiCreate, useApiSave } = useApiFactory('tax-rules')
const { taxTypes } = useTaxTypes()
const { handleSubmit, setErrors, setValues, resetForm } = useForm()

const { value: name, errorMessage: nameError } = useField('name')
const { value: taxType, errorMessage: taxTypeError } = useField('tax_type_id')
const { value: taxRate, errorMessage: taxRateError } = useField('tax_rate')

const { execute: executeCreate, loading: loadingCreate } = useApiCreate()
const { execute: executeSave, loading: loadingSave } = useApiSave()

const loading = computed(() => {
    return loadingCreate.value || loadingSave.value
})

watch(
    () => props.entity,
    (value) => {
        if (value) {
            setValues(value)
        } else {
            resetForm()
        }
    }
)

const closeModal = () => {
    emit('modal-close')
}

const onSave = handleSubmit(async (values) => {
    try {
        if (props.entity) {
            await executeSave(values, props.entity.id)
        } else {
            await executeCreate(values)
        }

        successNotify({
            title: t('general.success'),
            text: t('settingsTaxRules.upsert.successMessage'),
        })

        emit('on-save')
    } catch ({ errors }) {
        setErrors(errors)
    }
})
</script>
