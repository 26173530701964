<template>
    <data-grid
        :data-source="entities.data"
        :columns="columns"
        :loading="loadingSearch"
    >
        <template #outer-header>
            <div
                class="flex w-full items-start justify-between gap-2 border-b px-6 py-4"
            >
                <p class="font-medium leading-9 text-gray-900">
                    {{ $t('settingsUsers.list.roles.list.title') }}
                </p>
                <base-button
                    variant="default"
                    @click="
                        $router.push({
                            name: 'settings.roles.create',
                        })
                    "
                >
                    {{ $t('settingsUsers.list.roles.list.createBtn') }}
                </base-button>
            </div>
        </template>

        <template #column-name="{ item }">
            {{ item.display_name }}
        </template>

        <template #column-action="{ item }">
            <template v-if="$acl.can('update_user_settings')">
                <base-tippy
                    v-if="!item.editable || sameRole(item.id)"
                    :content="$t('settingsUsers.list.roles.cannotEditTooltip')"
                    placement="top"
                >
                    <base-button
                        v-if="$acl.can('update_user_settings')"
                        icon="line-icons:general:edit-01"
                        variant="tertiary-gray"
                        size="sm"
                        disabled
                    />
                </base-tippy>
                <base-button
                    v-else
                    icon="line-icons:general:edit-01"
                    variant="tertiary-gray"
                    size="sm"
                    @click="
                        $router.push({
                            name: 'settings.roles.edit',
                            params: { id: item.id },
                        })
                    "
                />
            </template>
            <base-button
                v-if="$acl.can('delete_user_settings')"
                icon="line-icons:general:trash-01"
                variant="tertiary-gray"
                size="sm"
                :disabled="!item.editable || item.users.length"
                @click="setEntity(item)"
            />
        </template>
    </data-grid>

    <base-delete-modal
        :loading="loadingDelete"
        :show="entity?.id"
        :name="entity?.display_name"
        @modal-close="setEntity(null)"
        @confirm-delete="confirmDelete"
    />
</template>

<script setup>
const { t } = useI18n()
const { user } = useAuth()
const sameRole = (roleId) => {
    return user.value.roles[0].id === roleId
}

const { entities, useApiSearch, useApiDelete, setEntity, entity } =
    useApiFactory('roles')
const { loading: loadingSearch, executeRefreshSearch } = useApiSearch()
const { execute: executeDelete, loading: loadingDelete } = useApiDelete()

const columns = [
    {
        property: 'name',
        label: t('settingsUsers.list.roles.list.columnName'),
    },
    {
        property: 'description',
        label: t('settingsUsers.list.roles.list.columnDescription'),
    },
]

const { successNotify, errorNotify } = useNotification()
const confirmDelete = async () => {
    try {
        const name = entity.value.display_name
        await executeDelete(entity.value.id)
        successNotify({
            text: t('settingsUsers.list.roles.deleteSuccess', {
                name,
            }),
        })
        await executeRefreshSearch()
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        setEntity(null)
    }
}
</script>
