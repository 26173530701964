<template>
    <base-sticky-heading
        class="min-h-[82px] items-center"
        :title="$t('settingsTaxRules.list.title')"
    >
        <template #right>
            <base-button
                v-if="$acl.can('create_tax_rules')"
                variant="primary"
                icon="line-icons:general:plus"
                @click="toggleModalUpsert()"
            >
                {{ $t('settingsTaxRules.list.addRule') }}
            </base-button>
        </template>

        <!-- Table -->
        <data-grid
            :data-source="entities.data"
            :columns="columns"
            :loading="loadingSearch"
            identify-field="id"
            table-borderless
        >
            <template #column-action="{ item }">
                <base-context-menu>
                    <base-context-menu-item
                        v-if="$acl.can('update_tax_rules')"
                        icon="line-icons:general:edit-01"
                        @click="toggleModalUpsert(item)"
                    >
                        {{ $t('settingsTaxRules.list.itemUpdate') }}
                    </base-context-menu-item>

                    <base-context-menu-item
                        v-if="item.editable && $acl.can('delete_tax_rules')"
                        icon="line-icons:general:trash-01"
                        @click="toggleModalDelete(item)"
                    >
                        {{ $t('settingsTaxRules.list.itemDelete') }}
                    </base-context-menu-item>
                </base-context-menu>
            </template>

            <template #column-name="{ item }">
                <div class="flex items-center gap-3">
                    <base-icon
                        v-if="!item.editable"
                        variant="inherit"
                        name="line-icons:security:lock-01"
                    />
                    <span class="text-sm font-medium text-primary-700">
                        {{ item.name }}
                    </span>
                </div>
            </template>

            <template #column-tax_rate="{ item }">
                <span class="text-sm font-normal text-gray-500">
                    {{ item.tax_rate }} %
                </span>
            </template>

            <template #footer>
                <grid-pagination
                    :total="entities.last_page"
                    :page="entities.current_page"
                />
            </template>
        </data-grid>
    </base-sticky-heading>

    <base-delete-modal
        :loading="loadingDelete"
        :show="state.showModalDelete"
        :name="entity?.name"
        @modal-close="toggleModalDelete(null)"
        @confirm-delete="confirmDelete"
    />

    <tax-rule-modal
        :show="state.showModalUpsert"
        :entity="entity"
        @modal-close="toggleModalUpsert(null)"
        @on-save="onSave"
    />
</template>

<script setup>
import { ITEM_PER_PAGE } from '@tenant/utils/constants'

const { t } = useI18n()

useHead({
    title: t('settingsTaxRules.list.title'),
})

const columns = computed(() => [
    {
        property: 'name',
        label: t('settingsTaxRules.list.nameColumn'),
        sortable: true,
    },
    {
        property: 'tax_rate',
        label: t('settingsTaxRules.list.taxRateColumn'),
        sortable: true,
    },
])

const { entity, entities, setEntity, useApiSearch, useApiDelete } =
    useApiFactory('tax-rules')

const {
    loading: loadingSearch,
    execute: executeSearch,
    executeRefreshSearch,
} = useApiSearch({
    page: 1,
    limit: ITEM_PER_PAGE,
})
const { loading: loadingDelete, execute: executeDelete } = useApiDelete()

const state = reactive({
    showModalUpsert: false,
    showModalDelete: false,
})

const toggleModalUpsert = (entity = null) => {
    state.showModalUpsert = !state.showModalUpsert
    setEntity(entity)
}

const toggleModalDelete = (entity = null) => {
    state.showModalDelete = !state.showModalDelete
    setEntity(entity)
}

const confirmDelete = () => {
    executeDelete(unref(entity).id).then(() => {
        toggleModalDelete(null)
        executeSearch()
    })
}

const onSave = () => {
    executeRefreshSearch().then(() => {
        toggleModalUpsert()
    })
}
</script>
