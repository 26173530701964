<template>
    <base-sticky-heading
        :title="$t('settingsCompany.title')"
        :description="$t('settingsCompany.description')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    v-if="$acl.can('update_companies')"
                    variant="primary"
                    icon="line-icons:general:check"
                    :loading="state.loading"
                    @click="onSave"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>

        <div class="px-8 py-6">
            <settings-company-address />
            <settings-company-tax />
            <settings-company-contact />
            <settings-company-payment />
            <settings-company-logo />
        </div>
    </base-sticky-heading>
</template>
<script setup>
const { successNotify } = useNotification()
const { handleSubmit, setValues, setErrors } = useForm()
const { useApiGet, useApiCreate } = useApiFactory('companies')

const { execute: executeGet } = useApiGet()
const { execute: executeSave } = useApiCreate()
const { t } = useI18n()
const emitter = useEmitter()
const { uploadStorage } = useUploadStorage()

const state = reactive({
    loading: false,
})

useHead({
    title: t('settingsCompany.title'),
})

const onSave = handleSubmit(async ({ logo, ...values }) => {
    try {
        state.loading = true
        let uploadKey = null
        if (logo) {
            uploadKey = await uploadStorage(logo)
        }

        await executeSave({
            ...values,
            logo: uploadKey,
        })

        successNotify({
            title: t('general.success'),
            text: t('settingsCompany.message.updateSuccessfully'),
        })
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        state.loading = false
    }
})

onMounted(async () => {
    emitter.emit('set-loading', true)

    const { company, info } = await executeGet()
    const {
        company_name,
        company_name_legal,
        director_name,
        company_type_id,
        address1,
        address2,
        postcode,
        city,
        country_id,
        company_industry_id,
        co_workers,
        registration_number,
        vat_id,
        tax_reference,
        is_cash_basis_on_payment_date,
        accounting_method_id,
        income_stmt_calculation_method_id,
        price_type_on_document,
        phone,
        tax,
        email,
        web_address,
        bank_name,
        account_number,
        sort_code,
        iban,
        swift_code,
    } = company || {}
    const { logo } = info || {}

    setValues({
        company_name,
        company_name_legal,
        director_name,
        company_type_id,
        address1,
        address2,
        postcode,
        city,
        country_id,
        company_industry_id,
        co_workers,
        registration_number,
        vat_id,
        tax_reference,
        is_cash_basis_on_payment_date,
        accounting_method_id,
        income_stmt_calculation_method_id,
        price_type_on_document,
        phone,
        tax,
        email,
        web_address,
        bank_name,
        account_number,
        sort_code,
        iban,
        swift_code,
        logo,
    })

    emitter.emit('set-loading', false)
})
</script>
