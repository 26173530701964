<template>
    <base-sticky-heading
        class="min-h-[82px] items-center"
        :title="$t('settingsTextTemplates.edit.title')"
        :description="$t('settingsTextTemplates.edit.description')"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    :loading="loadingSave"
                    type="submit"
                    @click="onSubmit"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>

        <settings-text-templates-form class="px-8 py-6" />
    </base-sticky-heading>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'
import { useApiFactory } from '@tenant/composables'
import emitter from '@tenant/core/init/emitter'

const { t } = useI18n()
const router = useRouter()

const { handleSubmit, setErrors, setValues } = useForm()
const { useApiGet, useApiSave } = useApiFactory('text-templates')
const { loading: loadingSave, execute: executeSave } = useApiSave()
const { execute: executeGet } = useApiGet()

useHead({ title: t('settingsTextTemplates.edit.title') })

const { errorNotify, successNotify } = useNotification()
const route = useRoute()

onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        const {
            name,
            type_id,
            type_line_item_id,
            type_module_id,
            is_default,
            contents,
        } = await executeGet(route.params.id)
        setValues({
            name,
            type_id,
            type_line_item_id,
            type_module_id,
            is_default,
            contents,
        })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const onCancel = () => {
    router.push({
        name: 'settings.text-templates.list',
    })
}

const onSubmit = handleSubmit((values) => {
    executeSave(values, route.params.id)
        .then(() => {
            successNotify({
                title: t('settingsTextTemplates.edit.saveSuccessMsg'),
            })

            router.push({
                name: 'settings.text-templates.list',
            })
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
})
</script>
