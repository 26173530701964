<template>
    <div class="h-px w-full border-t bg-gray-200 lg:w-full"></div>

    <div class="flex w-full flex-col gap-5 px-6 py-5">
        <form-single-select
            v-model="iso"
            :label="$t('settingsCurrencies.create.iso')"
            :error-message="errorIso"
            :options="currencyCodes"
            :disabled="props.editMode"
            required
        />

        <form-text-input
            v-model="name"
            :label="$t('settingsCurrencies.create.name')"
            :error-message="errorName"
            required
        />

        <form-text-input
            v-model="symbol"
            :label="$t('settingsCurrencies.create.symbol')"
            :error-message="errorSymbol"
            required
        />

        <form-text-input
            v-model="decimalPlaces"
            :label="$t('settingsCurrencies.create.decimalPlaces')"
            :error-message="errorDecimalPlaces"
            required
        />

        <form-text-input
            v-model="format"
            :label="$t('settingsCurrencies.create.format')"
            :error-message="errorFormat"
            required
        />
    </div>
</template>

<script setup>
const { value: iso, errorMessage: errorIso } = useField('iso')
const { value: id } = useField('id')
const { value: name, errorMessage: errorName } = useField('name')
const { value: symbol, errorMessage: errorSymbol } = useField('symbol')
const { value: decimalPlaces, errorMessage: errorDecimalPlaces } =
    useField('decimal_places')

const { value: format, errorMessage: errorFormat } = useField('format')
const { currenciesData } = useCurrencies()

const currencyCodes = ref([])

Object.keys(currenciesData).forEach((currency) => {
    currencyCodes.value.push({
        value: currency,
        label: currency,
    })
})

const props = defineProps({
    initialFinished: {
        type: Boolean,
        required: false,
        default: true,
    },
    editMode: {
        type: Boolean,
        required: false,
        default: false,
    },
})

watch(
    () => iso.value,
    (value) => {
        if (id.value && props.initialFinished) {
            return
        }

        if (value && props.initialFinished) {
            const currency = currenciesData[value]

            if (currency) {
                symbol.value = currency.currency_symbol
                decimalPlaces.value = currency.price_precision
                format.value = currency.currency_format
                name.value = currency.currency_name
            }
        }
    },
    { immediate: false }
)
</script>
