<template>
    <base-sticky-heading
        class="min-h-[82px] items-center"
        :title="$t('settingsTextTemplates.create.title')"
        :description="$t('settingsTextTemplates.create.description')"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button outline variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    :loading="loadingSave"
                    type="submit"
                    @click="onSubmit"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>

        <settings-text-templates-form class="px-8 py-6" />
    </base-sticky-heading>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'
import { useApiFactory } from '@tenant/composables'

const { t } = useI18n()
const router = useRouter()

const { handleSubmit, setErrors } = useForm()
const { useApiCreate } = useApiFactory('text-templates')
const { loading: loadingSave, execute: executeCreate } = useApiCreate()

useHead({ title: t('settingsTextTemplates.create.title') })

const onCancel = () => {
    router.push({
        name: 'settings.text-templates.list',
    })
}

const { successNotify } = useNotification()
const onSubmit = handleSubmit((values) => {
    executeCreate(values)
        .then(() => {
            successNotify({
                title: t('settingsTextTemplates.edit.saveSuccessMsg'),
            })

            router.push({
                name: 'settings.text-templates.list',
            })
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
})
</script>
