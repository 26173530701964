<template>
    <base-sticky-heading
        :title="$t('settingsSystem.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button variant="primary" @click="onSaveSetting">
                    {{ $t('settingsSystem.save') }}
                </base-button>
            </div>
        </template>

        <settings-system-close-books class="px-6 py-8" />
        <hr />

        <settings-system-cloud-storages />

        <settings-system-number-ranges
            v-if="$acl.can('view_sequence_settings')"
        />
    </base-sticky-heading>
</template>

<script setup>
const { t } = useI18n()
const { errorNotify, successNotify } = useNotification()
const emitter = useEmitter()

useHead({ title: t('settingsSystem.title') })

const { handleSubmit, setErrors, setValues } = useForm()

const { execute: executeGetConfig } = useApi('/api/settings', 'GET')
const { execute: executeSaveConfig } = useApi('/api/settings', 'POST')

onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        const result = await executeGetConfig({
            queries: {
                keys: [
                    // 'system.approvedToUseDocumentsForAITraining',
                    'system.closeBookEnable',
                    'system.closeBookDate',
                ],
            },
        })

        setValues({
            // approvedToUseDocumentsForAITraining:
            //     result['system.approvedToUseDocumentsForAITraining']?.value,
            closeBookEnable: result['system.closeBookEnable']?.value,
            closeBookDate: result['system.closeBookDate']?.value,
        })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const onSaveSetting = handleSubmit(
    async ({
        // approvedToUseDocumentsForAITraining,
        closeBookEnable,
        closeBookDate,
    }) => {
        try {
            emitter.emit('set-loading', true)

            await executeSaveConfig({
                data: {
                    payload: [
                        // {
                        //     key: 'system.approvedToUseDocumentsForAITraining',
                        //     value: {
                        //         value: approvedToUseDocumentsForAITraining,
                        //     },
                        // },
                        {
                            key: 'system.closeBookEnable',
                            value: {
                                value: closeBookEnable,
                            },
                        },
                        {
                            key: 'system.closeBookDate',
                            value: {
                                value: closeBookDate,
                            },
                        },
                    ],
                },
            })

            successNotify({
                text: t('settings.successMessage'),
            })
        } catch ({ errors }) {
            setErrors(errors)
        } finally {
            emitter.emit('set-loading', false)
        }
    }
)
</script>
