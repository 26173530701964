<template>
    <data-grid
        :data-source="entities.data"
        :columns="columns"
        :loading="loadingSearch"
    >
        <template #outer-header>
            <div
                class="flex w-full items-start justify-between gap-2 border-b px-6 py-4"
            >
                <p class="font-medium leading-9 text-gray-900">
                    {{ $t('settingsUsers.list.adminSection.title') }}
                </p>
                <base-button
                    v-if="$acl.can('update_user_settings')"
                    variant="default"
                    @click="
                        $router.push({
                            name: 'settings.users.invite',
                        })
                    "
                >
                    {{ $t('settingsUsers.list.adminSection.inviteBtn') }}
                </base-button>
            </div>
        </template>
        <template #column-name="{ item }">
            <users-avatar-group :user="item" />
        </template>
        <template #column-type="{ item }">
            <base-badge
                v-if="item.email_verified_at"
                :label="getRoleDisplayName(item.roles)"
                :variant="getRoleVariant(item.roles)"
            />
            <base-badge
                v-else
                :label="$t('settingsUsers.list.invited')"
                variant="info"
            />
        </template>
        <template #column-dateAdded="{ item }">
            {{ $filters.dateHumanFormat(item.created_at) }}
        </template>
        <template #column-action="{ item }">
            <base-button
                v-if="$acl.can('update_user_settings') && !item?.inviter_id"
                icon="line-icons:general:edit-01"
                variant="tertiary-gray"
                size="sm"
                :disabled="editDisabled(item)"
                @click="redirectToEdit(item)"
            />
            <base-button
                v-if="$acl.can('delete_user_settings')"
                icon="line-icons:general:trash-01"
                variant="tertiary-gray"
                size="sm"
                :disabled="deletedDisabled(item)"
                @click="setEntity(item)"
            />
        </template>
    </data-grid>

    <base-delete-modal
        :loading="loadingDelete || loadingDeleteInvite"
        :show="entity?.id"
        :name="entity?.full_name"
        :confirmation-message="$t('settingsUsers.list.deleteConfirmation')"
        :confirmation-value="entity?.firstName"
        @modal-close="
            () => {
                setEntity(null)
                setInvite(null)
            }
        "
        @confirm-delete="confirmDelete"
    />
</template>

<script setup>
import {
    getRoleDisplayName,
    getRoleName,
    getRoleVariant,
} from '@tenant/utils/helper'
import { USER_ROLES } from '@tenant/utils/constants'
import { useApiFactory } from '@tenant/composables'

const { t } = useI18n()
const router = useRouter()

const { user } = useAuth()

const { entities, entity, setEntity, useApiSearch, useApiDelete } =
    useApiFactory('users')
const { loading: loadingSearch, executeRefreshSearch } = useApiSearch()
const { loading: loadingDelete, execute: executeDelete } = useApiDelete()

const totalPrimaryUsers = computed(
    () => entities.value?.data?.filter((i) => i.is_primary_admin).length
)

const columns = [
    {
        property: 'name',
        label: t('settingsUsers.list.name'),
    },
    {
        property: 'type',
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
        label: t('settingsUsers.list.userType'),
    },
    {
        property: 'dateAdded',
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
        label: t('settingsUsers.list.dateAdded'),
    },
]

const { useApiDelete: useApiDeleteInvite, setEntity: setInvite } =
    useApiFactory('users-invites')
const { loading: loadingDeleteInvite, execute: executeDeleteInvite } =
    useApiDeleteInvite()

const { errorNotify } = useNotification()
const confirmDelete = async () => {
    try {
        if (entity.value?.inviter_id) {
            setInvite(entity.value)
            await executeDeleteInvite()
        } else {
            await executeDelete()
        }

        executeRefreshSearch()
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        setEntity(null)
        setEntity(null)
    }
}

const redirectToEdit = (item) => {
    if (user.value.id === item.id) {
        router.push({
            name: 'profile',
        })
    } else {
        router.push({
            name: 'settings.users.edit',
            params: { id: item.id },
        })
    }
}

const editDisabled = (item) => {
    if (user.value.id === item.id) {
        return false
    }

    if (item.is_primary_admin) {
        return true
    }

    const itemRole = getRoleName(item.roles)
    const userRole = getRoleName(user.value.roles)

    return itemRole === USER_ROLES.ADMIN && userRole === USER_ROLES.ADMIN
}

const deletedDisabled = (item) => {
    if (user.value.id === item.id) {
        return true
    }

    if (item.is_primary_admin && totalPrimaryUsers.value < 2) {
        return true
    }

    const itemRole = getRoleName(item.roles)
    const userRole = getRoleName(user.value.roles)

    return itemRole === USER_ROLES.ADMIN && userRole === USER_ROLES.ADMIN
}
</script>
