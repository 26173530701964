<template>
    <base-section
        :title="$t('settingsUsers.form.generalSection.title')"
        :description="$t('settingsUsers.form.generalSection.description')"
    >
        <template #right>
            <form-control-group>
                <form-control-group-row>
                    <form-single-select
                        v-model="salutation"
                        :label="$t('settingsUsers.form.salutation')"
                        :disabled="disabled"
                        :error-message="salutationError"
                        :options="salutations"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <form-text-input
                        v-model="firstName"
                        :label="$t('settingsUsers.form.firstName')"
                        :error-message="firstNameError"
                        :disabled="disabled"
                    />
                    <form-text-input
                        v-model="lastName"
                        :label="$t('settingsUsers.form.lastName')"
                        :error-message="lastNameError"
                        :disabled="disabled"
                    />
                </form-control-group-row>

                <form-control-group-row>
                    <form-text-input
                        v-model="abbreviation"
                        :label="$t('settingsUsers.form.abbreviation')"
                        :error-message="abbreviationError"
                        :disabled="disabled"
                    />
                </form-control-group-row>
            </form-control-group>
        </template>
    </base-section>
</template>

<script setup>
defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})

const { value: salutation, errorMessage: salutationError } =
    useField('salutation_id')
const { value: firstName, errorMessage: firstNameError } =
    useField('first_name')
const { value: lastName, errorMessage: lastNameError } = useField('last_name')
const { value: abbreviation, errorMessage: abbreviationError } =
    useField('abbreviation')

const { salutations } = useSalutations()
</script>
