<template>
    <div class="flex items-center justify-between border-gray-200 px-6 py-4">
        <h5 class="text-lg font-medium leading-9 text-gray-900">
            {{ $t('settings.title') }}
        </h5>
    </div>

    <div
        class="flex flex-col gap-2 overflow-auto px-6 py-5 text-md font-medium text-gray-700"
    >
        <router-link
            v-for="tab in tabs"
            :key="tab.name"
            :to="{
                name: tab.name,
            }"
            class="flex cursor-pointer items-center gap-3 rounded-md px-3 py-2"
            active-class="bg-primary-600 text-white"
        >
            {{ tab.label }}
        </router-link>
    </div>
</template>
<script setup>
const { t } = useI18n()
const acl = useAcl()
const tabs = computed(() => [
    ...(acl.can('view_companies')
        ? [
              {
                  name: 'settings.company',
                  label: t('settings.tabs.profile'),
              },
          ]
        : []),
    ...(acl.can('view_companies')
        ? [
              {
                  name: 'settings.system',
                  label: t('settings.tabs.system'),
              },
          ]
        : []),
    ...(acl.can('view_opening_balances')
        ? [
              {
                  name: 'settings.opening-balances',
                  label: t('settings.tabs.openingBalances'),
              },
          ]
        : []),
    ...(acl.can('view_user_settings')
        ? [
              {
                  name: 'settings.users',
                  label: t('settings.tabs.users'),
              },
          ]
        : []),
    ...(acl.can('view_currencies')
        ? [
              {
                  name: 'settings.currencies',
                  label: t('settings.tabs.currencies'),
              },
          ]
        : []),
    ...(acl.can('view_companies')
        ? [
              {
                  name: 'settings.invoices',
                  label: t('settings.tabs.invoices'),
              },
          ]
        : []),
    ...(acl.can('view_text_templates')
        ? [
              {
                  name: 'settings.text-templates',
                  label: t('settings.tabs.textTemplates'),
              },
          ]
        : []),
    ...(acl.can('view_tax_rules')
        ? [
              {
                  name: 'settings.taxRules.list',
                  label: t('settings.tabs.taxRules'),
              },
          ]
        : []),
])
</script>
