<template>
    <div>
        <div v-for="cloudStorage in cloudStorages" :key="cloudStorage.type">
            <base-section
                class="px-6"
                v-if="cloudStorage.type === AUTH_SOCIAL.GOOGLE"
                :title="$t('settingsSystem.cloudStorage.google.headline')"
                :description="
                    $t('settingsSystem.cloudStorage.google.description')
                "
            >
                <template #right>
                    <div
                        v-if="cloudStorage.active"
                        class="flex w-96 flex-col gap-3"
                    >
                        <base-button
                            outline
                            size="lg"
                            variant="gray"
                            icon="general:google-gray"
                            icon-class="text-transparent"
                            :label="
                                $t(
                                    'settingsSystem.cloudStorage.google.buttonDisconnect'
                                )
                            "
                            class="justify-center"
                            @click="onDisconnect(cloudStorage)"
                        />
                        <base-button
                            size="lg"
                            :label="
                                $t(
                                    'settingsSystem.cloudStorage.google.chooseFolder'
                                )
                            "
                            class="justify-center"
                            @click="openBillFolderModal(cloudStorage)"
                        />
                    </div>
                    <base-button
                        v-else
                        outline
                        size="lg"
                        variant="gray"
                        icon="general:google"
                        icon-class="text-transparent"
                        :label="
                            $t(
                                'settingsSystem.cloudStorage.google.buttonConnect'
                            )
                        "
                        class="w-96 justify-center"
                        @click="onConnect(cloudStorage.redirect_url)"
                    />
                </template>
            </base-section>
            <hr />
        </div>

        <settings-system-cloud-storage-bill-folder-modal
            :social="state.showBillFolderModal"
            @modal-close="closeBillFolderModal"
        />
    </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import { useEmitter } from '@tenant/composables'
import { AUTH_SOCIAL } from '@tenant/utils/constants'
import { useCloudStorages } from '@tenant/composables/apis/use-cloud-storages'

const { errorNotify } = useNotification()
const { cloudStorages, isLoading, refetch } = useCloudStorages()

const { execute } = useApi('/api/cloud-storages/remove', 'POST')

const emitter = useEmitter()
const { t } = useI18n()
const state = reactive({ showBillFolderModal: null })

watch(
    () => isLoading.value,
    (value) => {
        setLoading(value)
    }
)

onMounted(() => {
    setLoading(isLoading.value)
})

const setLoading = (loading) => {
    emitter.emit('set-loading', loading)
}

const onConnect = (redirectUrl) => {
    window.open(redirectUrl)
}

const openBillFolderModal = (cloudStorage) => {
    state.showBillFolderModal = cloudStorage.type
}

const closeBillFolderModal = () => {
    state.showBillFolderModal = null
}

const onDisconnect = async (cloudStorage) => {
    try {
        setLoading(true)
        await execute({ data: { social: cloudStorage.type } })
        await refetch()
    } catch {
        errorNotify({
            title: t('settingsSystem.message.disconnectFailed'),
        })
    } finally {
        setLoading(false)
    }
}
</script>
