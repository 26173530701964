<template>
    <data-grid
        :data-source="permissions || []"
        :columns="columns"
        :loading="loading"
        class="rounded-none border-0 border-t"
    >
        <template #header>
            {{ $t('settingsUsers.createRole.permissionsLabel') }}
        </template>

        <template #column-permission="{ item }">
            {{ item.name }}
        </template>

        <template #column-view="{ item }">
            <form-checkbox
                v-if="getPermission(item.permissions, 'view')"
                v-model="selectedItems"
                :value="getPermission(item.permissions, 'view')?.id"
            />
        </template>

        <template #column-create="{ item }">
            <form-checkbox
                v-if="getPermission(item.permissions, 'create')"
                v-model="selectedItems"
                :value="getPermission(item.permissions, 'create')?.id"
            />
        </template>

        <template #column-update="{ item }">
            <form-checkbox
                v-if="getPermission(item.permissions, 'update')"
                v-model="selectedItems"
                :value="getPermission(item.permissions, 'update')?.id"
            />
        </template>

        <template #column-delete="{ item }">
            <form-checkbox
                v-if="getPermission(item.permissions, 'delete')"
                v-model="selectedItems"
                :value="getPermission(item.permissions, 'delete')?.id"
            />
        </template>

        <template #column-all="{ item }">
            <form-checkbox
                :model-value="isAllChecked(item)"
                @update:model-value="onChange($event, item)"
            />
        </template>
    </data-grid>
</template>

<script setup>
const { t } = useI18n()

const {
    execute,
    loading,
    result: permissions,
} = useApi('api/permissions', 'GET')
onMounted(async () => {
    await execute()
})

const columns = [
    {
        property: 'permission',
        label: t('settingsUsers.createRole.grid.permissions'),
    },
    {
        property: 'view',
        label: t('settingsUsers.createRole.grid.view'),
    },
    {
        property: 'create',
        label: t('settingsUsers.createRole.grid.create'),
    },
    {
        property: 'update',
        label: t('settingsUsers.createRole.grid.update'),
    },
    {
        property: 'delete',
        label: t('settingsUsers.createRole.grid.delete'),
    },
    {
        property: 'all',
        label: t('settingsUsers.createRole.grid.all'),
    },
]

const { value: selectedItems } = useField('permissions', undefined, {
    initialValue: [],
})

const onChange = (checked, item) => {
    if (checked) {
        selectedItems.value = [
            ...selectedItems.value,
            ...item.permissions.map((permission) => permission.id),
        ]
    } else {
        selectedItems.value = selectedItems.value.filter(
            (id) =>
                !item.permissions
                    .map((permission) => permission.id)
                    .includes(id)
        )
    }
}

const isAllChecked = ({ permissions }) => {
    return permissions.every((permission) =>
        selectedItems.value.includes(permission.id)
    )
}

const getPermission = (permissions, key) => {
    return permissions.find((permission) => permission.name.includes(key))
}
</script>
