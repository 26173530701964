<template>
    <base-modal
        close
        :show="show"
        size="md"
        @modal-close="closeModal"
        :content-padding="false"
        :loading="loading || saveLoading"
    >
        <template #modal-header>
            <h3 class="text-2xl font-semibold">
                {{ $t('settingsCurrencies.edit.title') }}
            </h3>
        </template>

        <settings-currencies-form
            :initial-finished="initialFinished"
            :edit-mode="true"
        />

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>

                <base-button full-width variant="primary" @click="onSave">
                    {{ $t('settingsCurrencies.edit.btnSave') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emitter = useEmitter()
const emit = defineEmits(['modal-close', 'refresh'])
const { t } = useI18n()

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    currencyId: {
        type: String,
        required: true,
    },
})

const { useApiGet, useApiSave } = useApiFactory('currencies')
const { execute: executeGet, loading } = useApiGet()
const { successNotify, errorNotify } = useNotification()
const { setErrors, handleSubmit, setValues } = useForm()
const { execute: executeSave, loading: saveLoading } = useApiSave()

onMounted(async () => {
    await getCurrency(props.currencyId)
})

const initialFinished = ref(false)
const currency = ref(null)

const getCurrency = async (id) => {
    try {
        emitter.emit('set-loading', true)

        currency.value = await executeGet(id)

        initialFinished.value = true

        setValues({
            ...currency.value,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}

const onSave = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)

        await executeSave(values)

        successNotify({
            title: t('general.success'),
            text: t('settingsCurrencies.edit.successMessage'),
        })

        emit('refresh')
        emit('modal-close')
    } catch ({ errors }) {
        setErrors(errors)
        errorNotify({
            title: t('general.error'),
            text: t('settingsCurrencies.edit.errorMessage'),
        })
    } finally {
        emitter.emit('set-loading', false)
    }
})

const closeModal = () => {
    emit('modal-close')
}
</script>
