<template>
    <base-section
        :title="$t('settingsTextTemplates.general.sectionTitle')"
        :description="$t('settingsTextTemplates.general.sectionDescription')"
    >
        <template #right>
            <div class="grid grid-cols-2 grid-rows-2 gap-x-3 gap-y-4">
                <form-text-input
                    :label="$t('settingsTextTemplates.general.nameLabel')"
                    :hintText="$t('settingsTextTemplates.general.nameHelpText')"
                    v-model="name"
                    :error-message="errorName"
                />

                <form-single-select
                    :label="$t('settingsTextTemplates.general.typeLabel')"
                    :hintText="$t('settingsTextTemplates.general.typeHelpText')"
                    :options="textTemplateTypesOptions"
                    v-model="type"
                    :error-message="errorType"
                />

                <form-single-select
                    :label="$t('settingsTextTemplates.general.userLabel')"
                    :options="textTemplateModuleOptions"
                    v-model="moduleType"
                    :error-message="errorModuleType"
                />

                <form-single-select
                    :label="$t('settingsTextTemplates.general.lineItemLabel')"
                    :options="textTemplateLineItemOptions"
                    v-model="lineItemType"
                    :error-message="errorLineItemType"
                />
            </div>

            <form-toggle
                class="mb-8"
                :label="$t('general.setAsDefault')"
                v-model="isDefault"
                :error-message="errorIsDefault"
            />

            <div class="flex flex-col">
                <form-field-array
                    :min="1"
                    :fields="contents"
                    :label-add="$t('settingsTextTemplates.general.addItem')"
                    @on-remove-item="remove"
                    @on-add-item="onAddContentItem"
                >
                    <template #default="{ item: { key, value } }">
                        <form-control-group with-space class="grow">
                            <form-field-array-error
                                :name="`contents.${key}.language_id`"
                                #="{ errorMessage }"
                            >
                                <form-single-select
                                    :label="
                                        $t(
                                            'settingsTextTemplates.general.languageLabel'
                                        )
                                    "
                                    :options="textTemplateLanguagesOptions"
                                    v-model="value.language_id"
                                    :error-message="errorMessage"
                                />
                            </form-field-array-error>

                            <form-field-array-error
                                :name="`contents.${key}.content`"
                                #="{ errorMessage }"
                            >
                                <form-text-template-editor
                                    :label="
                                        $t(
                                            'settingsTextTemplates.general.textTemplateLabel'
                                        )
                                    "
                                    :template-type="type"
                                    :template-technical-type="moduleType"
                                    :template-line-item-type="lineItemType"
                                    class="mb-4"
                                    v-model="value.content"
                                    :error-message="errorMessage"
                                />
                            </form-field-array-error>

                            <form-field-array-error
                                :name="`contents.${key}.is_default`"
                                #="{ errorMessage }"
                            >
                                <form-toggle
                                    :label="$t('general.setAsDefault')"
                                    v-model="value.is_default"
                                    :disabled="
                                        hasTemplateIsDefault &&
                                        !value.is_default
                                    "
                                    :error-message="errorMessage"
                                />
                            </form-field-array-error>
                        </form-control-group>
                    </template>
                </form-field-array>
            </div>
        </template>
    </base-section>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useField, useFieldArray } from 'vee-validate'
import {
    useTextTemplateLanguages,
    useTextTemplateTypes,
} from '@tenant/composables/apis/use-text-template'

const { value: name, errorMessage: errorName } = useField('name')
const { value: type, errorMessage: errorType } = useField('type_id')
const { value: moduleType, errorMessage: errorModuleType } =
    useField('type_module_id')
const { value: lineItemType, errorMessage: errorLineItemType } =
    useField('type_line_item_id')
const { value: isDefault, errorMessage: errorIsDefault } = useField(
    'is_default',
    undefined,
    {
        initialValue: false,
    }
)
const { remove, push, fields: contents } = useFieldArray('contents')

const { textTemplateLanguages } = useTextTemplateLanguages()
const { textTemplateTypes } = useTextTemplateTypes()

const textTemplateLanguagesOptions = computed(() =>
    textTemplateLanguages.value.map((i) => ({ value: i.id, label: i.name }))
)

const textTemplateTypesOptions = computed(() =>
    textTemplateTypes.value.map((i) => ({ value: i.id, label: i.name }))
)

const textTemplateType = computed(() => {
    if (!type.value) {
        return null
    }

    return textTemplateTypes.value.find((i) => i.id === type.value)
})

const textTemplateModuleOptions = computed(() => {
    if (!textTemplateType.value) {
        return []
    }

    return (
        textTemplateType.value.text_template_type_modules?.map((i) => ({
            value: i.id,
            label: i.name,
        })) || []
    )
})

const textTemplateLineItemOptions = computed(() => {
    if (!textTemplateType.value) {
        return []
    }

    return (
        textTemplateType.value.text_template_type_line_items?.map((i) => ({
            value: i.id,
            label: i.name,
        })) || []
    )
})

const hasTemplateIsDefault = computed(() => {
    if (contents.value.length === 0) {
        return false
    }

    return contents.value.some((content) => content.value?.is_default)
})

onMounted(() => {
    onAddContentItem()
})

const onAddContentItem = () => {
    push({
        language_id: '',
        content: '',
        is_default: false,
    })
}
</script>
