<template>
    <base-sticky-heading
        :title="$t('settingsUsers.createRole.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    variant="default"
                    @click="$router.push({ name: 'settings.users.list' })"
                    :disabled="loadingSave"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    variant="primary"
                    @click="onSave"
                    :loading="loadingSave"
                >
                    {{ $t('settingsUsers.createRole.saveBtn') }}
                </base-button>
            </div>
        </template>

        <div class="px-8 py-6">
            <base-card body-classes="!p-0">
                <template #header>
                    <p class="text-lg font-medium text-gray-900">
                        {{ $t('settingsUsers.createRole.basicInfo') }}
                    </p>
                </template>

                <form-control-group class="p-6">
                    <form-control-group-row>
                        <form-text-input
                            v-model="name"
                            :error-message="errorName"
                            :label="$t('settingsUsers.createRole.nameLabel')"
                            :placeholder="
                                $t('settingsUsers.createRole.namePlaceholder')
                            "
                        />
                    </form-control-group-row>

                    <form-control-group-row>
                        <form-textarea
                            v-model="description"
                            :error-message="errorDescription"
                            :label="
                                $t('settingsUsers.createRole.descriptionLabel')
                            "
                            :placeholder="
                                $t(
                                    'settingsUsers.createRole.descriptionPlaceholder'
                                )
                            "
                            rows="5"
                        />
                    </form-control-group-row>
                </form-control-group>

                <roles-permission-grid />
            </base-card>
        </div>
    </base-sticky-heading>
</template>
<script setup>
const { t } = useI18n()

useHead({
    title: t('settingsUsers.createRole.title'),
})

const { handleSubmit, setErrors } = useForm()
const { value: name, errorMessage: errorName } = useField('display_name')
const { value: description, errorMessage: errorDescription } =
    useField('description')

const router = useRouter()
const { successNotify, errorNotify } = useNotification()
const { useApiCreate } = useApiFactory('roles')
const { execute: executeSave, loading: loadingSave } = useApiCreate()
const onSave = handleSubmit(async (values) => {
    try {
        await executeSave({
            ...values,
        })

        successNotify({
            text: t('settingsUsers.createRole.createSuccess'),
        })

        await router.push({ name: 'settings.users.list' })
    } catch ({ errors }) {
        setErrors(errors)
        if (errors?.permissions && Object.keys(errors).length === 1) {
            errorNotify({
                text: t('settingsUsers.createRole.permissionError'),
            })
        }
    }
})
</script>
