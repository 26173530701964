<template>
    <base-sticky-heading class="min-h-[82px] items-center">
        <template #title>
            <div class="flex flex-col gap-1">
                <div class="flex gap-x-2 gap-y-1">
                    <div class="text-lg font-medium text-gray-900">
                        {{ $t('settingsTextTemplates.title') }}
                    </div>
                </div>
                <div class="text-sm font-normal text-gray-500">
                    {{ $t('settingsTextTemplates.description') }}
                </div>
            </div>
        </template>

        <template #right>
            <div class="flex flex-col gap-x-3 gap-y-1 xl:flex-row">
                <base-button
                    v-if="$acl.can('create_text_templates')"
                    size="md"
                    variant="primary"
                    icon="line-icons:general:plus"
                    @click="redirectToCreateTemplate"
                >
                    {{ $t('settingsTextTemplates.addTextTemplate') }}
                </base-button>
            </div>
        </template>

        <data-grid
            :data-source="entities.data"
            :columns="columns"
            :loading="loadingSearch"
            :empty-text="$t('settingsTextTemplates.list.emptyText')"
            :empty-sub="$t('settingsTextTemplates.list.emptySubText')"
            table-borderless
        >
            <template #column-name="{ item }">
                {{ item.name }}
            </template>

            <template #column-type="{ item }">
                {{ getItemType(item) }}
            </template>

            <template #column-preview="{ item }">
                <span
                    v-html="getDefaultContent(item)"
                    class="h-5 w-64 truncate"
                ></span>
            </template>

            <template #column-is_default="{ item }">
                <button
                    v-if="$acl.can('update_text_templates')"
                    class="rounded-md bg-primary-50 p-3"
                    @click="toggleModalMarkDefault(item)"
                    :disabled="item.is_default"
                >
                    <base-icon
                        name="line-icons:general:check-circle"
                        :variant="item.is_default ? 'grayLight' : 'primary'"
                    />
                </button>
            </template>

            <template #column-action="{ item }">
                <base-context-menu>
                    <base-context-menu-item
                        v-if="$acl.can('update_text_templates')"
                        icon="line-icons:general:edit-01"
                        @click="
                            $router.push({
                                name: 'settings.text-templates.edit',
                                params: { id: item.id },
                            })
                        "
                    >
                        {{ $t('settingsTextTemplates.editBtn') }}
                    </base-context-menu-item>

                    <base-context-menu-item
                        v-if="$acl.can('delete_text_templates')"
                        icon="line-icons:general:trash-01"
                        :is-disabled="!!item.is_default"
                        @click="toggleModalDelete(item)"
                    >
                        {{ $t('settingsTextTemplates.deleteBtn') }}
                    </base-context-menu-item>
                </base-context-menu>
            </template>

            <template #footer>
                <grid-pagination
                    :total="entities.last_page"
                    :page="entities.current_page"
                />
            </template>
        </data-grid>
    </base-sticky-heading>

    <base-mark-default-modal
        :loading="loadingMarkAsDefault"
        :show="showModalMarkDefault"
        :name="entity?.name"
        @modal-close="toggleModalMarkDefault(null)"
        @on-submit="confirmMarkDefault"
    />

    <base-delete-modal
        :loading="loadingDelete"
        :show="showModalDelete"
        :name="entity?.name"
        :confirmation-message="
            $t('settingsTextTemplates.list.deleteConfirmation')
        "
        @modal-close="toggleModalDelete(null)"
        @confirm-delete="confirmDelete"
    />
</template>

<script setup>
import { ref, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import { useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useApi, useApiFactory } from '@tenant/composables'

const { t } = useI18n()
const router = useRouter()

useHead({ title: t('settingsTextTemplates.title') })

const { entities, entity, setEntity, useApiSearch, useApiDelete } =
    useApiFactory('text-templates')
const { loading: loadingSearch, execute: executeSearch } = useApiSearch()
const { loading: loadingDelete, execute: executeDelete } = useApiDelete()

const { loading: loadingMarkAsDefault, execute: executeMarkAsDefault } = useApi(
    'api/text-templates/{id}/set-default',
    'PATCH'
)

const showModalMarkDefault = ref(false)
const showModalDelete = ref(false)

const columns = [
    {
        property: 'name',
        label: t('settingsTextTemplates.list.name'),
        sortable: true,
    },
    {
        property: 'type',
        label: t('settingsTextTemplates.list.type'),
        headerCellClass: 'w-[35%]',
        sortable: true,
    },
    {
        property: 'preview',
        label: t('settingsTextTemplates.list.preview'),
        headerCellClass: 'w-[35%]',
        sortable: true,
    },
    {
        property: 'is_default',
        label: t('settingsTextTemplates.list.standard'),
        headerCellClass: '[&>*]:justify-center',
        dataCellClass: '[&>*]:justify-center',
    },
]

const toggleModalMarkDefault = (entity = null) => {
    showModalMarkDefault.value = !isEmpty(entity)
    setEntity(entity)
}

const toggleModalDelete = (entity = null) => {
    showModalDelete.value = !isEmpty(entity)
    setEntity(entity)
}

const confirmDelete = () => {
    executeDelete(unref(entity).id).then(() => {
        toggleModalDelete(null)
        executeSearch()
    })
}

const confirmMarkDefault = () => {
    executeMarkAsDefault({
        params: { id: unref(entity).id },
    }).then(() => {
        toggleModalMarkDefault(null)
        executeSearch()
    })
}

const redirectToCreateTemplate = () => {
    router.push({ name: 'settings.text-templates.create' })
}

const getItemType = (item) => {
    return [
        item.text_template_type.name,
        item.text_template_type_module.name,
        item.text_template_type_line_item.name,
    ]
        .filter(Boolean)
        .join(' - ')
}

const getDefaultContent = (item) => {
    if (!item.contents) {
        return null
    }

    const contentDefault = item.contents.find((content) => content.is_default)
    if (!contentDefault) {
        return null
    }

    return contentDefault.content
}
</script>
