<template>
    <base-section :title="$t('settingsCompany.payment.title')">
        <template #description>
            <span class="text-sm font-normal text-gray-500">
                {{ $t('settingsCompany.payment.secondDescription') }}
            </span>
            <span class="text-sm font-normal text-gray-500">
                {{ $t('settingsCompany.payment.firstDescription') }}
            </span>
        </template>
        <template #right>
            <div class="grid grid-cols-2 gap-x-3 gap-y-4">
                <form-text-input
                    class="col-span-2"
                    :label="$t('settingsCompany.payment.bankNameLabel')"
                    :error-message="errorBankName"
                    v-model="bankName"
                />
                <form-text-input
                    :label="$t('settingsCompany.payment.accountNumberLabel')"
                    :error-message="errorAccountNumber"
                    v-model="accountNumber"
                />
                <form-text-input
                    :label="$t('settingsCompany.payment.sortCodeLabel')"
                    :error-message="errorSortCode"
                    v-model="sortCode"
                />
                <form-text-input
                    class="col-span-2"
                    :label="$t('settingsCompany.payment.ibanLabel')"
                    :error-message="errorIban"
                    v-model="iban"
                />
                <form-text-input
                    class="col-span-2"
                    :label="$t('settingsCompany.payment.swiftCodeLabel')"
                    :error-message="errorSwiftCode"
                    v-model="swiftCode"
                />
            </div>
        </template>
    </base-section>
</template>

<script setup>
import { useField } from 'vee-validate'
const { value: bankName, errorMessage: errorBankName } = useField('bank_name')
const { value: accountNumber, errorMessage: errorAccountNumber } =
    useField('account_number')
const { value: sortCode, errorMessage: errorSortCode } = useField('sort_code')
const { value: iban, errorMessage: errorIban } = useField('iban')
const { value: swiftCode, errorMessage: errorSwiftCode } =
    useField('swift_code')
</script>
